import React from "react"
import Title from "components/text/Title"
import FormFieldGroup from "components/forms/FormFieldGroup"
import {
  accountInfoFields,
  accountInfoYupSchema,
} from "features/checkout/steps/forms/AccountInfoForm"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "components/buttons/Button"
import useAuth from "hooks/auth/useAuth"
import { CheckoutData } from "../CheckoutSteps"

interface Props {
  onSubmit: (data: object) => void
  checkoutData: CheckoutData
}

const AccountInfo: React.FC<Props> = ({ onSubmit, checkoutData }) => {
  const user = useAuth()
  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(accountInfoYupSchema),
    values: checkoutData.accountInfo,
  })

  return (
    <div className="px-container-padding-desktop flex flex-col gap-6 items-center w-full max-w-container-max-width-desktop">
      <div className="flex flex-col gap-6 w-full max-w-3xl">
        <div className="flex flex-col gap-8 w-full">
          <div className="flex flex-row gap-4 w-full">
            <Title>Account Information</Title>
          </div>
        </div>
        <div className="bg-primary rounded-xl border border-tertiary flex flex-col w-full shadow-lg">
          <div className="p-6 flex flex-col gap-6 w-full">
            <form>
              <FormFieldGroup
                fields={accountInfoFields(user)}
                control={control}
                columns={2}
              />
              <div className="mt-5">
                <Button
                  size="s"
                  onClick={handleSubmit((data: object) => {
                    onSubmit(data)
                  })}
                >
                  Continue to shipping
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountInfo
