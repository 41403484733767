import Text from "components/text/Text"

export const AsyncConsultationErrorBoundary = () => (
  <div className="flex flex-col gap-10 text-lg">
    <Text>
      This form is currently unavailable because your DrWell healthcare provider
      may not be accepting online consultation requests at this time.
    </Text>
    <Text>
      For the most up-to-date availability and alternative booking options,
      please contact your DrWell provider directly. They can assist you with
      scheduling your consultation through other channels or inform you when
      online submissions resume.
    </Text>
  </div>
)
