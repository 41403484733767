import { useGetUserAddressesQuery } from "../../app/services/userAdressApi"
import { getMyId } from "../../helpers/authHelpers"
import { UserAddress } from "../../types/entities/userAddress"
import { useEffect, useMemo, useState } from "react"
import { CheckoutData, DefaultCheckoutData } from "./CheckoutSteps"
import { Subscription, SubscriptionReferralLink } from "../../types/entities/subscriptionTypes"
import { User } from "../../types/entities/userTypes"
import useLoadFinixScript from "../finix/useLoadFinixScript"
import useGetFinixFraudSessionId from "../finix/useGetFinixFraudSessionId"
import { getFinixIdempotencyId } from "../finix/finixHelpers"

const useGetDefaultCheckoutData = (
  subscription: Subscription,
  referralLink: SubscriptionReferralLink,
  user: User | null,
) => {
  const { isFinixScriptLoaded } = useLoadFinixScript()
  const fraudSessionId = useGetFinixFraudSessionId(isFinixScriptLoaded)
  const { data: addressesResponse } = useGetUserAddressesQuery({
    user: getMyId(),
  })

  const address: UserAddress | undefined = useMemo(
    () =>
      addressesResponse?.items?.length ? addressesResponse.items[0] : undefined,
    [addressesResponse],
  )

  const [checkoutData, setCheckoutData] = useState<CheckoutData>(
    DefaultCheckoutData(subscription, referralLink, user, address),
  )

  useEffect(() => {
    if (address && fraudSessionId) {
      setCheckoutData(
        DefaultCheckoutData(
          subscription,
          referralLink,
          user,
          address,
          user?.credit_authorization ? fraudSessionId : undefined,
          user?.credit_authorization ? getFinixIdempotencyId() : undefined,
        ),
      )
    }
  }, [address, fraudSessionId])

  return { checkoutData, setCheckoutData }
}

export default useGetDefaultCheckoutData