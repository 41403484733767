import React, { useMemo } from "react"
import Text from "components/text/Text"
import { ProviderLogo } from "types/asyncConsultation"
import Image from "components/images/Image"
import { useWindowSize } from "hooks/useAsyncConsult/useWindowsSize"
import drWellLogo from "assets/images/logo.svg"

interface Props {
  title?: string
  description?: string
  providerLogo?: ProviderLogo
  subTitle?: string
}

const BREAKPOINTS = {
  DESKTOP: 1024,
  TABLET: 768,
  MOBILE: 648,
} as const

const isProviderLogoAvailable = (providerLogo?: ProviderLogo) => {
  return (
    !!providerLogo?.large ||
    !!providerLogo?.medium ||
    !!providerLogo?.small ||
    !!providerLogo?.original
  )
}

const Header: React.FC<Props> = ({
  title,
  description,
  providerLogo,
  subTitle,
}) => {
  const windowSize = useWindowSize()
  const imgSrc = useMemo(() => {
    if (!isProviderLogoAvailable(providerLogo)) return drWellLogo

    switch (true) {
      case windowSize > BREAKPOINTS.DESKTOP:
        return providerLogo?.original
      case windowSize > BREAKPOINTS.TABLET:
        return providerLogo?.large
      case windowSize > BREAKPOINTS.MOBILE:
        return providerLogo?.medium
      default:
        return providerLogo?.small
    }
  }, [windowSize, providerLogo])

  return (
    <div className="mb-16">
      <Image
        src={imgSrc}
        alt={"provider-logo-image"}
        className={"mb-8 w-auto max-w-full h-[30px] max-h-24"}
      />
      <Text family="header" size="3xl" weight="bold" className="mb-6">
        {title}
      </Text>
      <Text color="grey" size="m" className="leading-5" family="primary">
        {description}
      </Text>
      {subTitle && (
        <Text className="mt-12" weight="bold" size="2xl">
          {subTitle}
        </Text>
      )}
    </div>
  )
}

export default Header
