import { parsePhoneNumberFromString } from "libphonenumber-js"

export const phoneToShortForm = (phone: string): string => {
  if (!phone) {
    return "";
  }

  let digits = phone.replace(/\D/g, "");

  if (digits.length === 11 && digits.startsWith("1")) {
    digits = digits.slice(1);
  }

  return digits;
};


export function formatPhoneNumber(phoneNumber: string | undefined) {
  if (!phoneNumber) return

  const parsedNumber = parsePhoneNumberFromString(phoneNumber, "US")
  if (!parsedNumber) return phoneNumber

  return parsedNumber.formatNational()
}

export const formatPhoneNumberInput = (value: string): string => {
  const phoneNumber = phoneToShortForm(value)
  if (phoneNumber.length <= 3) {
    return phoneNumber
  }

  if (phoneNumber.length >= 4 && phoneNumber.length <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  if (phoneNumber.length > 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  return phoneNumber
}
