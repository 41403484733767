import { useEffect, useState } from "react"
import {
  useLazyGetAccountByTokenQuery,
  useLoginBYWellnessCodeMutation,
} from "app/services"
import { User } from "types/entities/userTypes"

interface Props {
  userId: string
  onSuccess: (token: string, user: User, code: string) => void
}

const useInputWellnessCode = ({ userId, onSuccess, }: Props) => {
  const [code, setCode] = useState<string>('')
  const [login, { isLoading: isCodeLoading, data: codeData, isError }] =
    useLoginBYWellnessCodeMutation()
  const [fetchUser, { data: userData, isLoading: isUserLoading }] =
    useLazyGetAccountByTokenQuery()
  const isLoading = isCodeLoading || isUserLoading

  useEffect(() => {
    if (codeData && codeData?.token) {
      fetchUser(codeData.token)
    }
  }, [codeData])

  useEffect(() => {
    if (userData && codeData?.token) {
      onSuccess(codeData.token, userData, code)
    }
  }, [userData])

  const handleSumbit = (value: string) => {
    setCode(value)
    login({ user_id: userId, wellness_key: value })
  }

  return {
    handleSumbit,
    isLoading,
    error: isError ? "Invalid code. Please try again." : undefined,
  }
}

export default useInputWellnessCode
