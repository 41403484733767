import { FC, ReactNode } from "react"

interface Props {
  children?: ReactNode
  size?: "lg" | "md"
}

const InputLabel: FC<Props> = ({ children, size = "md" }) => {
  if (!children) return null
  const style = {
    md: "mb-3 text-base text-jacarta font-semibold",
    lg: 'font-medium text-xl mb-4 block text-[#101828]',
  }
  return <div className={style[size]}>{children}</div>
}

export default InputLabel
