import { format as dateFnsFormat, parseISO } from "date-fns"

export const DATE_FORMAT_DEFAULT = "MM/dd/yyyy"
export const DATE_FORMAT_FULL_MONTH = "LLL dd, yyyy"

export const formatDate = (
  date: Date | string | null | undefined,
  format: string = DATE_FORMAT_DEFAULT,
): string | null => {
  if (!date) {
    return null
  }

  return dateFnsFormat(date, format)
}

/**
 * Format date of birth to desired format without timezone shift
 *
 * @param date
 *
 * @param format
 */
export const formatBirthDate = (
  date: Date | string | null | undefined,
  format: string = DATE_FORMAT_DEFAULT,
): string | null => {
  if (!date) return null;

  let parsedDate: Date;

  // Parse the date if it's a string
  if (typeof date === "string") {
    parsedDate = parseISO(date);
  } else {
    parsedDate = date;
  }

  // Format the date without timezone shift
  return dateFnsFormat(new Date(parsedDate.getUTCFullYear(), parsedDate.getUTCMonth(), parsedDate.getUTCDate()), format);
};

export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Add leading 0 for months
  const day = String(date.getDate()).padStart(2, "0") // Add leading 0 for days
  return `${year}-${month}-${day}`
}
