import Text from "components/text/Text"
import Button from "components/buttons/Button"
import { Link } from "react-router-dom"

export const SubmitSuccess = () => {
  return (
    <div className="flex gap-5 flex-col">
      <Text size="xl">
        Your responses have been sent to our medical team for review. We'll
        contact you soon about next steps for your care.
      </Text>
      <div className="flex justify-center mt-4">
        <div className="mb-6">
          <Button size="m" type={"button"} isRounded>
            <Link to="/account">Manage My Account</Link>
          </Button>
        </div>
      </div>
    </div>
  )
}
