import Alert from "components/notifications/Alert"
import Text from "components/text/Text"
import React from "react"

export const WarningAlert = () => {
  return (
    <Alert className="flex ite p-4  border border-red-200 rounded-lg bg-red-50 mb-16">
      <Text
        color="grey"
        size="l"
        weight="bold"
        family="primary"
        className="pl-2 text-left"
      >
        Please Complete All Questions
      </Text>
      <Text
        color="grey"
        size="m"
        family="primary"
        className="pl-2 text-left landing-5"
      >
        We need your full responses to provide the most accurate care
        recommendations. Review any highlighted questions and provide the
        missing information.
      </Text>
    </Alert>
  )
}
