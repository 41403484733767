import React from "react"
import { InputHTMLAttributes } from "react"
import InputLabel from "./InputLabel"

interface TextAreaInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string
  name?: string
  required?: boolean
  placeholder?: string
  value: any
  rows?: number
  errorMessage?: string
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  label,
  name,
  required = false,
  placeholder = "",
  rows = 6,
  value,
  errorMessage,
  ...inputProps
}) => {
  return (
    <div className="mb-4">
      <InputLabel size="lg">{label}</InputLabel>
      <textarea
        id={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
        className={`mt-2 p-3 w-full  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${errorMessage ? "border border-red" : "border border-gray-300"}`}
        {...inputProps}
      />
    </div>
  )
}

export default TextAreaInput
