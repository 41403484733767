export const Checkbox = ({
  label,
  checked,
  onChange,
  error,
}: {
  label: string
  checked: boolean
  onChange: () => void
  error?: boolean
}) => {
  return (
    <label
      className={`flex items-center space-x-3 cursor-pointer group border-2 rounded-lg px-4 py-6 w-full bg-white ${
        checked
          ? "border-black text-black"
          : error
            ? "border-error"
            : "border-gray-100 text-gray-800"
      }`}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="w-5 h-5 accent-black bg-gray-100 border-gray-300 rounded focus:ring-black focus:ring-2"
      />
      <span className="group-hover:text-black">{label}</span>
    </label>
  )
}
