import { entityId } from "types/core/basicEntityTypes"

export interface Drug extends entityId {
  name: DrugType
}

export enum DrugType {
  TIRZEPATIDE = "Tirzepatide",
  SEMAGLUTIDE = "Semaglutide",
  SEMAGLUTIDENAD = "Semaglutide/NAD+",
  TIRZEPATIDEGLYCINE = "Tirzepatide with glycine",
  TIRZEPATIDELCARNITINE = "Tirzepatide/Levocarnitine",
  MICPLUSB1230 = "MIC Drop + B12 injection 30mL",
  MICPLUSB1210 = "MIC Drop + B12 injection 10mL",
  NADPLUSNASAL = "NAD+ Nasal Spray",
  NADPLUSINJECTION = "NAD+ Injection",
  NADPLUSINJECTION1005 = "NAD+ injection 100mg/mL 5mL vial",
  NADPLUSINJECTION10010 = "NAD+ injection 100mg/mL 10mL vial",
  NADPLUSINJECTION20010 = "NAD+ injection 200mg/mL in 10mL vial",
  NADPLUSSERMORELIN = "",
  SERMORELININJECTION = "Sermorelin injection",
  TESTOSTERONE5 = "Testosterone Cypionate 5mL vial",
  TESTOSTERONE10 = "Testosterone Cypionate 10mL vial",
  BLT = "Benzocaine/Lidocaine/Tetracaine 20/10/10 plasticized base 60gm",
  BUPROPIONHCL = "Bupropion HCL SR",
  BUPROPIONNALTREXONE = "Bupropion/Naltrexone",
  GLP1SQ = "Semaglutide and Tirzepatide",
  TIRZEPATIDEGLYCINEB12 = "Tirzepatide/Glycine/B12",
  TIRZEPATIDEGLYCINEB1212ML = "Tirzepatide/Glycine/B12 12mL vial",
  TIRZEPATIDEGLYCINEB1216ML = "Tirzepatide/Glycine/B12 16mL vial",
  TIRZEPATIDEGLYCINEB128ML = "Tirzepatide/Glycine/B12 8mL vial",
  TIRZEPATIDEMETHLYCOBALAMIN = "Tirzepatide with methylcobalamin",
  TIRZEPATIDEPRESERVATIVEFREE = "Tirzepatide Preservative-Free",
  TRETINOIN25 = "Tretinoin 0.025%",
  TRETINOIN5 = "Tretinoin 0.05%",
  TRETINOIN1 = "Tretinoin 0.1% ",
}
