import { useLayoutEffect, useRef, useState } from "react"

export const useWindowSize = () => {
  const width = useRef(window.innerWidth)
  const [_, forceRender] = useState(0)

  useLayoutEffect(() => {
    const handleResize = () => {
      width.current = window.innerWidth
      forceRender((prev) => prev + 1)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return width.current
}
