import { apiV2 } from "./apiV2"
import {
  SubmitFormPayload,
  SubmitFormResponse,
} from "../../types/asyncConsultation"

export const submitFormApi = apiV2.injectEndpoints({
  endpoints: (build) => ({
    submitForm: build.mutation<SubmitFormResponse, SubmitFormPayload>({
      query: (body) => ({
        url: `/form-submission`,
        method: "POST",
        body: body,
      }),
    }),
  }),
})
export const { useSubmitFormMutation } = submitFormApi
