import { FieldError, UseFormRegister } from "react-hook-form"
import TextInput from "./TextInput"
import { FormInitialValues } from "types/asyncConsultation"

export type RadioOption = {
  label: string
  value: string | boolean
  conditionalForm?: {
    id: string
    conditionalValue: string
    title: string
  }
}
interface RadioProps {
  checked: boolean
  option: RadioOption
  onChange: () => void
  error?: boolean | string
  register: UseFormRegister<FormInitialValues>
  formDataName: string
  errors?: { [key: string]: FieldError }
}

export const Radio = ({
  checked,
  option,
  onChange,
  error,
  register,
  formDataName,
  errors,
}: RadioProps) => {
  return (
    <>
      <label
        className={`flex items-center space-x-3 cursor-pointer group border-2 rounded-lg px-4 py-6 w-full bg-white ${
          checked
            ? "border-black text-black"
            : error
              ? "border-error"
              : "border-gray-100 text-gray-800"
        }`}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="w-5 h-5 accent-black bg-gray-100 border-gray-300 rounded focus:ring-black focus:ring-2"
        />
        <span className="group-hover:text-black">{option.label}</span>
      </label>
      {checked === true && option.conditionalForm &&  (
        <TextInput
          label={`${option.conditionalForm?.title}:*`}
          className="rounded-md"
          placeholder="Enter details"
          inputSize="s"
          {...register(`${formDataName}.${option.conditionalForm?.id}`, {
            required: checked === true && option.label === "Yes",
          })}
          error={!!errors?.[option.conditionalForm?.id]}
        />
      )}
    </>
  )
}
