import React, { useMemo } from "react"
import {
  FormSubGroup,
  FormSubGroupSection,
  FormInitialValues,
} from "types/asyncConsultation"
import Button from "components/buttons/Button"
import {
  Control,
  UseFormWatch,
  UseFormRegister,
  UseFormGetFieldState,
} from "react-hook-form"
import { CustomFormField } from "./CustomFormField"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import RightIcon from "assets/images/RightIcon"

interface Props {
  formData?: FormSubGroup
  control: Control<FormInitialValues, any>
  watch: UseFormWatch<FormInitialValues>
  register: UseFormRegister<FormInitialValues>
  getFieldState: UseFormGetFieldState<FormInitialValues>
  errors?: any
  buttonLabel?: string
  buttonType?: "submit" | "button"
  handleButtonClick: () => void
}

export const FormBuilder: React.FC<Props> = ({
  formData,
  control,
  register,
  errors,
  buttonLabel,
  buttonType,
  handleButtonClick,
}) => {
  const structuredForms = useMemo(
    () =>
      formData?.formSubGroupSections
        ?.map((section) => ({
          ...section,
          formInputs: section.formInputs
            .map((o) => o)
            .sort((a, b) => a.sortOrder - b.sortOrder),
        }))
        .sort((a, b) => a.sortOrder - b.sortOrder) || [],
    [formData],
  )

  const handleNext = () => {
    handleButtonClick()
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100)
  }

  return (
    <div className="space-y-16">
      {structuredForms.map((data: FormSubGroupSection, idx: number) => (
        <CustomFormField
          key={`${idx}.${data.id}`}
          data={data}
          control={control}
          register={register}
          errors={errors}
          name={formData ? formData.name : ""}
        />
      ))}
      <div className="flex justify-end">
        <div className="mb-6">
          {buttonLabel && (
            <Button
              size="xs"
              type={buttonType}
              onClick={handleNext}
              icon={false ? <LoadingSvg /> : <RightIcon disabled={false} />}
              isRounded
            >
              {false ? "Please wait..." : buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
