import { FormFieldI } from "types/core/formTypes"
import { FormFieldType } from "constants/core/formConstants"
import { buildYupSchema } from "helpers/core/formHelpers"
import * as yup from "yup"
import "yup-phone-lite"
import { User } from "types/entities/userTypes"
import { getYupBirthDateValidationRules } from "../../../../utils/validation/inputValidation";

export const accountInfoFields = (user?: User | null): FormFieldI[] => [
  {
    type: FormFieldType.TEXT,
    name: "firstName",
    label: "First name",
    placeholder: "First name",
    rules: yup.string().required("First name is required"),
  },
  {
    type: FormFieldType.TEXT,
    name: "lastName",
    label: "Last name",
    placeholder: "Last name",
    rules: yup.string().required("Last name is required"),
  },
  {
    type: FormFieldType.DATE,
    name: "dateOfBirth",
    label: "Date of birth",
    placeholder: "Date of birth",
    rules: getYupBirthDateValidationRules()
  },
  {
    type: FormFieldType.SELECT,
    name: "gender",
    label: "Gender",
    placeholder: "Gender",
    rules: yup.string().required("Gender is required"),
    options: [
      {
        value: "male",
        label: "Male",
      },
      {
        value: "female",
        label: "Female",
      },
    ],
  },
  {
    type: FormFieldType.TEXT,
    name: "phone",
    label: "Mobile number",
    placeholder: "Mobile number",
    rules: yup.string().phone("US", "Invalid phone number").required(),
    disabled: !!user?.phone,
  },
  {
    type: FormFieldType.TEXT,
    name: "email",
    label: "Email",
    placeholder: "Email",
    rules: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    disabled: !!user?.email,
  },
]

export const accountInfoYupSchema = buildYupSchema(accountInfoFields())
