import { useState } from "react"

interface Props {
  onSubmit: (value: string) => void
}

const useWellnessCodeForm = ({ onSubmit }: Props) => {
  const [code, setCode] = useState("")

  const handleInputChange = (val: string) => {
    setCode(val)
  }

  const handleSubmit = () => {
    onSubmit(code)
  }

  return {
    handleInputChange,
    isSubmitDisabled: code.length < 6,
    handleSubmit,
    code
  }
}

export default useWellnessCodeForm
