import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useAppDispatch } from "app/hooks"
import { useGenerateWellnessCodeMutation } from "app/services"
import { WellnessCodeRequest } from "types/authTypes"
import { formatPhoneNumberInput } from "utils/phoneUtils"
import { WellnessCodeType } from "constants/authConstants"
import { setLastLoginType } from "./authSlice"
import {
  EMAIL_REGEX_WITH_CODE,
  PHONE_REGEX_WITH_CODE,
} from "constants/validationConstants"
import { useNavigate } from "react-router-dom"
interface Props {
  setUserId: (id: string) => void
  setSignInValue: (value: WellnessCodeRequest) => void
  inPopup: boolean
}

const useSendWellnessCode = ({ setUserId, setSignInValue, inPopup }: Props) => {
  const [phoneEmailValue, setPhoneEmailValue] = useState("")
  const [isAccountDeactivated, setIsAccountDeactivated] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [sendCode, { isLoading, data}] = useGenerateWellnessCodeMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data?.id) {
      if (setUserId) {
        setUserId(String(data.id))
      }
    }
  }, [data])

  const handlePhoneValueSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    const type: WellnessCodeType = detectInputType(phoneEmailValue)

    if (type === WellnessCodeType.EMAIL) {
      if (!EMAIL_REGEX_WITH_CODE.test(phoneEmailValue)) {
        setError("This email address isn't valid. Please check and try again.")
        return
      }
    }

    if (type === WellnessCodeType.PHONE) {
      if (!PHONE_REGEX_WITH_CODE.test(phoneEmailValue)) {
        setError("This phone number isn't valid. Please check and try again.")
        return
      }
    }

    const signInValue = { type, value: phoneEmailValue }
    dispatch(setLastLoginType(type))
    setError(undefined)
    setSignInValue(signInValue)
    const response = await sendCode(signInValue)
    // @ts-ignore
    if(response?.error?.data?.detail === 'User is deactivated') { 
      if(!inPopup) {
        navigate('/deactivation-success')
      } else {
        setIsAccountDeactivated(true)
      } 
    }
  }

  const deformatInvalidPhoneNumber = (value: string) => {
    return value.replace(
      /^\((.{3})\) (.{1,3})(-(.{1,}))?$/,
      (match, p1, p2, p3, p4) => {
        return p1 + (p2 || "") + (p4 || "")
      },
    )
  }

  const detectInputType = (value: string): WellnessCodeType => {
    const rawValue = value.replace(/[)( -]/g, "")
    const isNumeric = /^\d+$/.test(rawValue)

    if (isNumeric) {
      return WellnessCodeType.PHONE
    } else {
      return WellnessCodeType.EMAIL
    }
  }
  const handlePhoneEmailInputChange = (e: ChangeEvent<any>): void => {
    const value = e.target.value
    const inputType = detectInputType(value)

    if (inputType === WellnessCodeType.PHONE) {
      const formattedPhone = formatPhoneNumberInput(value)
      setPhoneEmailValue(formattedPhone)
    } else {
      const formattedEmail = deformatInvalidPhoneNumber(value)
      setPhoneEmailValue(formattedEmail)
    }
  }

  return {
    handlePhoneEmailInputChange,
    handlePhoneValueSubmit,
    phoneEmailValue,
    isButtonDisabled: !Boolean(phoneEmailValue),
    isLoading,
    error,
    isAccountDeactivated,
  }
}

export default useSendWellnessCode
