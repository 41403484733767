import React from "react"
import Text from "components/text/Text"
import Button from "components/buttons/Button"
import RightIcon from "assets/images/RightIcon"

interface Props {
  handleButtonClick: () => void
  isNextDisabled: boolean
}

export const Landing: React.FC<Props> = ({ handleButtonClick, isNextDisabled }) => {
  return (
    <div className="h-[calc(100vh-290px)]">
      <Text size="xl" family="body">
        We'll ask you a series of questions to help determine which treatments
        and services best match your needs.
      </Text>
      <br />
      <Text size="xl" family="body">
        This personalized assessment will help us understand your goals and
        create recommendations tailored specifically for you.
      </Text>
      <br />
      <Text size="xl" family="body">
        Take your time answering each question - the more detail you provide,
        the better we can assist you.
      </Text>
      <div className="flex justify-end mt-12">
        <div className="mb-6">
          <Button
            size="xs"
            onClick={handleButtonClick}
            icon={<RightIcon disabled={isNextDisabled} />}
            isRounded
          >
            Get started
          </Button>
        </div>
      </div>
    </div>
  )
}
