import SubscriptionSummary from "features/checkout/steps/SubscriptionSummary"
import React, { useState, useEffect, FC } from "react"
import AccountInfo from "features/checkout/steps/AccountInfo"
import ShippingInfo from "features/checkout/steps/ShippingInfo"
import PaymentInfo from "features/checkout/steps/PaymentInfo"
import Review from "features/checkout/steps/Review"
import ProgressBar from "features/checkout/ProgressBar"
import { CheckoutDataKey, CheckoutStep } from "features/checkout/CheckoutSteps"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Subscription,
  SubscriptionReferralLink,
} from "types/entities/subscriptionTypes"
import Confirmation from "features/checkout/steps/Confirmation"
import useAuth from "hooks/auth/useAuth"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import useGetDefaultCheckoutData from "./useGetDefaultCheckoutData"

const Checkout: FC = () => {
  const navigate = useNavigate();
  const user = useAuth();

  // Hooks must be called unconditionally at the top level
  const location = useLocation();
  const subscription = location.state?.subscription as Subscription;
  const referralLink = location.state?.referralLink as SubscriptionReferralLink;

  const [currentStep, setCurrentStep] = useState<CheckoutStep>(
    CheckoutStep.SUMMARY,
  );

  const { checkoutData, setCheckoutData } = useGetDefaultCheckoutData(
    subscription,
    referralLink,
    user,
  )

  const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null);

  useEffect(() => {
    if (!user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  if (!subscription) {
    return null;
  }

  const handleCheckoutData = (step: CheckoutStep, data: object) => {
    const stepData: Partial<CheckoutDataKey> = {};
    stepData[step] = data;

    setCheckoutData((prevData) => ({
      ...prevData,
      ...stepData,
    }));
  }

  const onCheckoutSubmit = (userSubscription: UserSubscription) => {
    setUserSubscription(userSubscription);
    setNextStep(CheckoutStep.CONFIRMATION);
  }

  const setNextStep = (step: CheckoutStep) => {
    switch (step) {
      case CheckoutStep.ACCOUNT_INFO:
        const info = checkoutData.accountInfo
        if (
          info.firstName &&
          info.lastName &&
          info.dateOfBirth &&
          info.gender &&
          info.email &&
          info.phone
        ) {
          setNextStep(CheckoutStep.SHIPPING_INFO);
        } else {
          setCurrentStep(CheckoutStep.ACCOUNT_INFO);
        }
        break
      case CheckoutStep.SHIPPING_INFO:
        const shipping = checkoutData.shippingInfo
        if (
          shipping.firstName &&
          shipping.lastName &&
          shipping.streetAddress &&
          shipping.city &&
          shipping.state &&
          shipping.zipCode
        ) {
          setNextStep(CheckoutStep.PAYMENT_INFO)
        } else {
          setCurrentStep(CheckoutStep.SHIPPING_INFO)
        }
        break
      case CheckoutStep.PAYMENT_INFO:
        if (
          checkoutData.paymentInfo.finixToken &&
          checkoutData.paymentInfo.creditProcessor === "finix"
        ) {
          setNextStep(CheckoutStep.REVIEW)
        } else {
          setCurrentStep(CheckoutStep.PAYMENT_INFO)
        }
        break
      default:
        setCurrentStep(step)
        break
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case CheckoutStep.SUMMARY:
        return (
          <SubscriptionSummary
            subscription={subscription}
            onSubmit={() => setNextStep(CheckoutStep.ACCOUNT_INFO)}
          />
        )
      case CheckoutStep.ACCOUNT_INFO:
        return (
          <AccountInfo
            onSubmit={(data) => {
              handleCheckoutData(CheckoutStep.ACCOUNT_INFO, data)
              setNextStep(CheckoutStep.SHIPPING_INFO)
            }}
            checkoutData={checkoutData}
          />
        )
      case CheckoutStep.SHIPPING_INFO:
        return (
          <ShippingInfo
            onSubmit={(data) => {
              handleCheckoutData(CheckoutStep.SHIPPING_INFO, data)
              setNextStep(CheckoutStep.PAYMENT_INFO)
            }}
            checkoutData={checkoutData}
          />
        )
      case CheckoutStep.PAYMENT_INFO:
        return (
          <PaymentInfo
            onSubmit={(data) => {
              handleCheckoutData(CheckoutStep.PAYMENT_INFO, data)
              setNextStep(CheckoutStep.REVIEW)
            }}
            checkoutData={checkoutData}
          />
        )
      case CheckoutStep.REVIEW:
        return (
          <Review
            subscription={subscription}
            onSubmit={onCheckoutSubmit}
            initialCheckoutData={checkoutData}
          />
        )
      case CheckoutStep.CONFIRMATION:
        return (
          <Confirmation
            subscription={subscription}
            userSubscription={userSubscription}
          />
        )
      default:
        return null
    }
  }

  const progressBarSteps = [
    {
      label: "Account",
      isActive: currentStep === CheckoutStep.ACCOUNT_INFO,
      isCompleted:
        currentStep !== CheckoutStep.SUMMARY &&
        currentStep !== CheckoutStep.ACCOUNT_INFO,
    },
    {
      label: "Shipping",
      isActive: currentStep === CheckoutStep.SHIPPING_INFO,
      isCompleted: [CheckoutStep.PAYMENT_INFO, CheckoutStep.REVIEW].includes(
        currentStep,
      ),
    },
    {
      label: "Payment",
      isActive: currentStep === CheckoutStep.PAYMENT_INFO,
      isCompleted: currentStep === CheckoutStep.REVIEW,
    },
    {
      label: "Review",
      isActive: currentStep === CheckoutStep.REVIEW,
      isCompleted: false,
    },
  ]

  const renderProgressBar = () => {
    const hasActiveStep = progressBarSteps.some((step) => step.isActive)

    return hasActiveStep && <ProgressBar steps={progressBarSteps} />
  }

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-6 items-center w-full">
        <div className="flex flex-col gap-6 items-center w-full max-w-screen-lg">
          {renderProgressBar()}
          {renderStep()}
        </div>
      </div>
    </div>
  )
}

export default Checkout
