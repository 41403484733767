import React, { useState } from "react"
import BaseFooter from "layouts/BaseFooter"
import BaseHeader from "layouts/BaseHeader"
import SubscriptionPage from "features/subscriptions/SubscriptionPage"
import { Logo } from "../types/entities/physicianLogo"

const Subscription = () => {
  const [customLogo, setCustomLogo] = useState<Logo | null>(null)

  return (
    <>
      <BaseHeader customLogo={customLogo} />
        <SubscriptionPage setLogo={setCustomLogo} />
      <BaseFooter />
    </>
  )
}

export default Subscription
