import React, { useState } from "react"
import FormFieldGroup from "components/forms/FormFieldGroup"
import {
  accountInfoFields,
  accountInfoYupSchema,
} from "features/checkout/steps/forms/AccountInfoForm"
import Button from "components/buttons/Button"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { CheckoutData } from "features/checkout/CheckoutSteps"
import useAuth from "hooks/auth/useAuth"
import { capitalizeFirstChar } from "utils/commonUtils"
import { formatPhoneNumber } from "utils/phoneUtils"

interface Props {
  onSubmit: (data: object) => void
  checkoutData: CheckoutData
}

const ReviewAccountInformation: React.FC<Props> = ({
  onSubmit,
  checkoutData,
}) => {
  const user = useAuth()
  const [isChange, setIsChange] = useState(false)

  const { control, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(accountInfoYupSchema),
    defaultValues: checkoutData.accountInfo,
  })

  if (isChange) {
    return (
      <div>
        <div className="flex justify-between items-center mb-6">
          <div className="text-lg font-semibold">Account information</div>
        </div>
        <form>
          <FormFieldGroup
            fields={accountInfoFields(user)}
            control={control}
            columns={2}
          />

          <div className="border-t border-gray-300 my-5"></div>

          <div className="mt-5 pb-spacing-xl flex flex-col sm:flex-row gap-spacing-xl items-center sm:space-x-4 justify-end self-stretch shrink-0 relative">
            <div>
              <Button
                size="s"
                color="white"
                onClick={() => {
                  setIsChange(false)
                  reset(checkoutData.accountInfo)
                }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                size="s"
                onClick={handleSubmit((data: object) => {
                  onSubmit(data)
                  setIsChange(false)
                })}
              >
                Save changes
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  let formatDateOfBirth = (date: string | undefined) => {
    if (!date) return

    const dateOfBirth = new Date(date)

    const month = String(dateOfBirth.getUTCMonth() + 1).padStart(2, "0")
    const day = String(dateOfBirth.getUTCDate()).padStart(2, "0")
    const year = dateOfBirth.getUTCFullYear()

    return `${month}/${day}/${year}`
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="text-lg font-semibold">Account information</div>
        <div
          className="text-md font-semibold text-gray-500 cursor-pointer"
          onClick={() => {
            setIsChange(true)
          }}
        >
          Change
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8">
        <div>
          <div className="text-sm text-gray-500">Name</div>
          <div className="text-md font-medium text-gray-900">
            {checkoutData.accountInfo.firstName}{" "}
            {checkoutData.accountInfo.lastName}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Gender</div>
          <div className="text-md font-medium text-gray-900">
            {capitalizeFirstChar(checkoutData.accountInfo.gender)}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Date of birth</div>
          <div className="text-md font-medium text-gray-900">
            {formatDateOfBirth(checkoutData.accountInfo.dateOfBirth)}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Email</div>
          <div className="text-md font-medium text-gray-900">
            {checkoutData.accountInfo.email}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Mobile number</div>
          <div className="text-md font-medium text-gray-900">
            {formatPhoneNumber(checkoutData.accountInfo.phone)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewAccountInformation
