import React, { useEffect } from "react"
import ButtonGroup from "components/buttons/ButtonGroup"
import TextInput from "components/inputs/TextInput"
import {
  Controller,
  UseFormRegister,
  Control,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { FormInitialValues } from "types/asyncConsultation"
import SelectInput from "components/inputs/SelectInput"
import { stateOptions } from "constants/core/stateConstants"
import { formatPhoneNumberInput } from "utils/phoneUtils"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import RightIcon from "assets/images/RightIcon"
import Button from "components/buttons/Button"
import { accountInformationRules } from "utils/validation/inputValidation"
import { useSelector } from "react-redux"
import { User } from "types/entities/userTypes"
import { selectCurrentUser, selectLastLoginType } from "features/auth/authSlice"
import { convertIncheToFeetandInch } from "utils/commonUtils"
import { genderOptions } from "constants/asyncConsultation"

interface Props {
  register: UseFormRegister<FormInitialValues>
  control: Control<FormInitialValues, any>
  handleButtonClick: (code: string) => Promise<void>
  setValue: UseFormSetValue<FormInitialValues>
  isLoading: boolean
  errors: any
  watch: UseFormWatch<FormInitialValues>
  isValid: boolean
  selectedState: string
}

const acoountInformationInputKeys: (keyof User)[] = [
  "name_first",
  "name_last",
  "birth_date",
  "gender_assigned_at_birth",
  "email",
  "phone",
  "weight",
  "height_inches",
]

const AccountInformationForm: React.FC<Props> = (props) => {
  const {
    register,
    control,
    handleButtonClick,
    isLoading,
    errors,
    setValue,
    watch,
    selectedState,
  } = props
  const user = useSelector(selectCurrentUser)
  const lastLoginType = useSelector(selectLastLoginType)

  useEffect(() => {
    if (user) {
      acoountInformationInputKeys.forEach((key) => {
        if (key === "birth_date" && !!user.birth_date) {
          setValue("registration.birth_date", user.birth_date.split("T")[0])
        } else if (key === "height_inches" && !!user?.height_inches) {
          const { feet, inches } = convertIncheToFeetandInch(
            user.height_inches as number,
          )
          setValue("registration.height_feet", `${feet}`)
          setValue("registration.height_inches", inches)
        } else if (key === "phone" && !!user?.phone) {
          setValue("registration.phone", formatPhoneNumberInput(user.phone))
        } else {
          setValue(`registration.${key}`, user[key])
        }
      })
      if (selectedState) {
        const state = stateOptions.find((option) => option.label.includes(selectedState))
        setValue("registration.address_state", state?.value || "")
      }
    }
  }, [user, setValue, selectedState])
  
  const values = watch('registration')
  
  const isValid = Object.values(values).every((v) => !!v)

  return (
    <div className="mx-auto rounded-lg space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <TextInput
          label="First name"
          className="rounded-md"
          placeholder="First name"
          {...register(
            "registration.name_first",
            accountInformationRules.nameFirst,
          )}
          error={errors?.["name_first"]?.message}
        />
        <TextInput
          label="Last name"
          className="rounded-md"
          placeholder="Last name"
          {...register(
            "registration.name_last",
            accountInformationRules.nameLast,
          )}
          error={errors?.["name_last"]?.message}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <TextInput
          label="Date of birth"
          className="rounded-md"
          placeholder="MM/DD/YYYY"
          type="date"
          {...register(
            "registration.birth_date",
            accountInformationRules.birthDate,
          )}
          error={errors?.["birth_date"]?.message}
        />
        <div className="flex flex-col">
          <span className="mb-[14px] text-sm font-medium">Gender *</span>
          <Controller
            name="registration.gender_assigned_at_birth"
            control={control}
            rules={accountInformationRules.gender}
            render={({ field: { value, onChange: onGenderChange } }) => (
              <ButtonGroup
                value={Array.isArray(value) ? value[0] : value}
                options={genderOptions}
                onChange={onGenderChange}
                error={errors?.["gender_assigned_at_birth"]?.message}
              />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Controller
          name="registration.phone"
          control={control}
          rules={accountInformationRules.phone}
          render={({ field: { value, onChange: onPhoneNumberChange } }) => {
            return (
              <TextInput
                label="Mobile number"
                className="rounded-md"
                disabled={lastLoginType === "phone"}
                placeholder="(407) 555-1234"
                value={value}
                onChange={(e) => {
                  const phoneValue = formatPhoneNumberInput(
                    e.target.value as string,
                  )
                  onPhoneNumberChange(phoneValue)
                }}
                error={errors?.["phone"]?.message}
              />
            )
          }}
        />

        <TextInput
          label="Email"
          className="rounded-md"
          placeholder="Email"
          disabled={lastLoginType === "email"}
          type="email"
          {...register("registration.email", accountInformationRules.email)}
          error={errors?.["email"]?.message}
        />
      </div>
      <div>
        <Controller
          name="registration.address_state"
          control={control}
          rules={accountInformationRules.state}
          render={({ field: { value, onChange } }) => {
            return (
              <SelectInput
                label="Select state"
                options={stateOptions.map((option) => ({
                  ...option,
                  displayTitle: `${option.label} - ${option.value}`,
                }))}
                disabled={!!selectedState}
                value={value}
                onChange={onChange}
                error={errors?.["state"]?.message}
              />
            )
          }}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <TextInput
          label="Weight (in lbs.)"
          placeholder="Weight"
          type="number"
          className="rounded-md"
          {...register("registration.weight", accountInformationRules.weight)}
          error={errors?.["weight"]?.message}
        />
        <div className="grid grid-cols-2 gap-2">
          <TextInput
            label="Height - Feet"
            className="rounded-md"
            placeholder="Feet"
            type="number"
            {...register(
              "registration.height_feet",
              accountInformationRules.heightFeet,
            )}
            error={errors?.["height_feet"]?.message}
          />
          <TextInput
            label="Height - Inches"
            className="rounded-md"
            placeholder="Inches"
            type="number"
            {...register(
              "registration.height_inches",
              accountInformationRules.heightInches,
            )}
            error={errors?.["height_inches"]?.message}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="mt-12">
          <Button
            size="xs"
            onClick={() => handleButtonClick(user?.wellness_key as string)}
            icon={
              isLoading ? <LoadingSvg /> : <RightIcon disabled={!isValid} />
            }
            disabled={!isValid}
            isRounded
          >
            {isLoading ? "Please wait..." : "Continue"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AccountInformationForm
