import {
  Subscription,
  SubscriptionType,
} from "types/entities/subscriptionTypes"
import { DrugType } from "types/entities/drugTypes"
import SemaglutideImage from "assets/images/medications/semaglutide.png"
import SemaglutideNadImage from "assets/images/medications/semaglutide-nad.png"
import TirzepatideImage from "assets/images/medications/tirzepatide.png"
import TirzepatideGlycineImage from "assets/images/medications/tirzepatide-glycine.png"
import TirzepatideLCarnitineImage from "assets/images/medications/tirzepatide-l-carnitine.png"
import MicPlusB12Image from "assets/images/medications/mic-plus-b12.png"
import NadPlusImage from "assets/images/medications/nad-plus.png"
import NadPlusSermorelinImage from "assets/images/medications/nad-plus-sermorelin.png"
import SermorelinImage from "assets/images/medications/sermorelin.png"
import TestosteroneImage from "assets/images/medications/testosterone.png"
import BltImage from "assets/images/medications/BLT-Skin-Cream-Tub.png"
import BupropionImage from "assets/images/medications/Bupropion.png"
import Glp1sqImage from "assets/images/medications/GLP-1sq.png"
import TirzepatideFreeImage from "assets/images/medications/Tirzepatide-Preservative-Free.png"
import TirzepatideGlycineB12Image from "assets/images/medications/Tirzepatide-Glycine-B12.png"
import TirzepatideMethylImage from "assets/images/medications/Tirzepatide-Methyl.png"
import TretinoinImage from "assets/images/medications/Tretinoin-Tube.png"

export const getFrequency = (subscription: Subscription) => {
  switch (subscription.billing_period || "monthly") {
    case "custom":
      return `Every ${subscription.billingcustom} days`
    case "monthly":
      return "Every month"
    case "quarterly":
      return "Every quarter"
    case "6-month":
      return "Every 6 months"
    case "yearly":
      return "Every year"
  }
}
export const replaceTypeInText = (text: string, type: SubscriptionType) => {
  const typeText = getTypeName(type)

  return text.replace("{type}", typeText)
}

export const getTypeName = (type: SubscriptionType) => {
  return type === SubscriptionType.SUBSCRIPTION ? "subscription" : "membership"
}

export const getDrugImage = (drugName: string | undefined) => {
  if (drugName === DrugType.SEMAGLUTIDE) {
    return SemaglutideImage
  }
  if (drugName === DrugType.SEMAGLUTIDENAD) {
    return SemaglutideNadImage
  }
  if (drugName === DrugType.TIRZEPATIDE) {
    return TirzepatideImage
  }
  if (drugName === DrugType.TIRZEPATIDEGLYCINE) {
    return TirzepatideGlycineImage
  }
  if (drugName === DrugType.TIRZEPATIDELCARNITINE) {
    return TirzepatideLCarnitineImage
  }
  if (drugName === DrugType.MICPLUSB1230 || drugName === DrugType.MICPLUSB1210) {
    return MicPlusB12Image
  }
  if (drugName === DrugType.NADPLUSNASAL) {
    return NadPlusImage
  }
  if (drugName === DrugType.NADPLUSSERMORELIN) {
    return NadPlusSermorelinImage
  }
  if (drugName === DrugType.SERMORELININJECTION) {
    return SermorelinImage
  }
  if (drugName === DrugType.TESTOSTERONE5 || drugName === DrugType.TESTOSTERONE10) {
    return TestosteroneImage
  }
  if (drugName === DrugType.BLT) {
    return BltImage
  }
  if (drugName === DrugType.BUPROPIONHCL || drugName === DrugType.BUPROPIONNALTREXONE) {
    return BupropionImage
  }
  if (drugName === DrugType.GLP1SQ) {
    return Glp1sqImage
  }
  if (drugName === DrugType.TIRZEPATIDEPRESERVATIVEFREE) {
    return TirzepatideFreeImage
  }
  if (drugName === DrugType.TIRZEPATIDEGLYCINEB12 || drugName === DrugType.TIRZEPATIDEGLYCINEB1212ML || drugName === DrugType.TIRZEPATIDEGLYCINEB1216ML || drugName === DrugType.TIRZEPATIDEGLYCINEB128ML) {
    return TirzepatideGlycineB12Image
  }
  if (drugName === DrugType.TIRZEPATIDEMETHLYCOBALAMIN) {
    return TirzepatideMethylImage
  }
  if (drugName === DrugType.TRETINOIN25 || drugName === DrugType.TRETINOIN5 || drugName === DrugType.TRETINOIN1) {
    return TretinoinImage
  }
  return null
}
