import { FormInitialValues, SubmitFormPayload } from "types/asyncConsultation"
import { UUID4_REGEX } from "constants/validationConstants"
import { User } from "types/entities/userTypes"

export const formatSubmitPayload = (
  data: FormInitialValues,
  user: User,
  providerFormId: string,
): SubmitFormPayload => {
  const res: Record<string, string | boolean> = {}

  const processValue = (key: string, value: any) => {
    if (Array.isArray(value)) {
      value.forEach((id) => (res[id] = true))
    } else if (UUID4_REGEX.test(value)) {
      res[value] = true
    } else {
      res[key] = value
    }
  }

  Object.entries(data).forEach(([key, subField]) => {
    if (key === "registration") return

    if (Array.isArray(subField)) {
      subField.forEach((id) => (res[id] = true))
    } else {
      Object.entries(subField).forEach(([id, value]) => processValue(id, value))
    }
  })

  const userInputData: Partial<User> = data.registration as User
  const height =
    12 * Number(userInputData.height_feet) +
    Number(userInputData?.height_inches)

  return {
    deletedDate: null,
    providerFormId,
    response: res,
    formSubmissionStatus: "new",
    V1_userId: user.id as string,
    user: {
      V1_userId: user.id as string,
      deletedDate: null,
      nameFirst: userInputData.name_first ?? "",
      nameLast: userInputData.name_last ?? "",
      birthDate: userInputData.birth_date ?? "",
      emails: [{email: userInputData.email ?? "", type: "primary"}] ?? [],
      phone: userInputData.phone ?? "",
      genderAssignedAtBirth: userInputData.gender_assigned_at_birth ?? "",
      addressState: userInputData.address_state ?? "",
      weight: userInputData.weight ?? 0,
      height: height ?? 0,
    },
  }
}

type RegistrationFormReturnType = {
  payload: Partial<User> | null
  error: string | null
}

type FormatRegisterPayloadParams =
  | User
  | { [sectionName: string]: string | string[] }
  | string[]

export const formatRegisterPayload = (
  data: FormatRegisterPayloadParams,
  code: string,
): RegistrationFormReturnType => {
  const newUser: Partial<User> = { wellness_key: code }
  let error = ""
  let height = 0

  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case "height_feet":
        height += parseInt(value, 10) * 12
        break
      case "height_inches":
        height += parseFloat(value)
        break
      default:
        newUser[key as keyof User] = value
    }
  }

  newUser.height_inches = height
  newUser.weight = Number(newUser.weight)

  return { payload: error ? null : newUser, error }
}
