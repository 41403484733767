import React from "react"

interface Props {
  length: number
  selected?: number
}

export const Stepper: React.FC<Props> = ({ length = 5, selected }) => {
  return (
    <div className="flex w-full gap-4 mb-6">
      {Array(length)
        .fill(0)
        .map((_, index: number) => (
          <div
            key={index}
            className={`h-3 flex-1 rounded-full ${selected && index < selected ? "bg-slate-500" : "bg-[#E4E7EC]"}`}
          />
        ))}
    </div>
  )
}
