import React, { FC, useEffect, useState } from "react"
import { Code } from "react-content-loader"
import {
  PaymentInstrumentResponse,
  UserIdentity,
} from "types/entities/finixTypes"
import {
  useCreateFinixPaymentInstrumentMutation,
  useSetFinixUserIdentityMutation,
} from "app/services/finixApi"
import Alert from "components/notifications/Alert"
import useAuth from "hooks/auth/useAuth"
import useLoadFinixScript from "./useLoadFinixScript"
import useGetFinixFraudSessionId from "./useGetFinixFraudSessionId"
import { getFinixIdempotencyId } from "./finixHelpers"

declare const Finix: {
  CardTokenForm: (formId: string, options: any) => any
}

interface TokenData {
  id: string
}

interface FenixForm {
  submit: (
    environment: string,
    applicationId: string,
    callback: (err: any, res: { data: TokenData }) => void,
  ) => void
}

export type FinixOnSuccess = ({
  paymentInstrument,
  fraudSessionId,
  idempotencyId,
}: {
  paymentInstrument: PaymentInstrumentResponse
  fraudSessionId: string
  idempotencyId: string
}) => void

interface Props {
  buttonLabel?: string
  onSuccess: FinixOnSuccess
  identity?: UserIdentity
  isDisabled?: boolean
}

const FinixPaymentForm: FC<Props> = ({
  buttonLabel = "Submit",
  onSuccess,
  identity,
  isDisabled = false,
}) => {
  const user = useAuth()
  const [isLoading, setLoading] = useState(true)
  const [isProcessing, setProcessing] = useState(false)
  const [isError, setError] = useState(false)
  const [createInstrument] = useCreateFinixPaymentInstrumentMutation()
  const [setIdentity] = useSetFinixUserIdentityMutation()
  const { isFinixScriptLoaded } = useLoadFinixScript()
  const fraudSessionId = useGetFinixFraudSessionId(isFinixScriptLoaded)

  useEffect(() => {
    if (isFinixScriptLoaded && fraudSessionId) {
      const onSubmit = () => {
        setProcessing(true)
        setError(false)

        form.submit(
          process.env.REACT_APP_FINIX_ENVIRONMENT as string,
          process.env.REACT_APP_FINIX_APPLICATION_ID as string,
          async (err, res) => {
            let finixIdentity

            const handleError = () => {
              setProcessing(false)
              setError(true)
            }

            if (err) {
              handleError()
              return
            }

            if (!user?.finix_identity) {
              if (!identity) {
                identity = {
                  email: user?.email || "",
                  firstName: user?.name_first || "",
                  lastName: user?.name_last || "",
                  phone: user?.phone || "",
                }
              }

              const identityResponse = await setIdentity(identity)
              if (!identityResponse?.data?.id) {
                handleError()
                return
              }

              finixIdentity = identityResponse.data.id
            } else {
              finixIdentity = user?.finix_identity
            }

            const paymentInstrument = await createInstrument({
              token: res.data.id,
              identity: finixIdentity,
            })
            if (!paymentInstrument?.data?.id) {
              handleError()
              return
            }

            onSuccess({
              paymentInstrument: paymentInstrument.data,
              fraudSessionId,
              idempotencyId: getFinixIdempotencyId(),
            })
            setProcessing(false)
          },
        )
      }

      const options = {
        styles: {
          default: {
            color: "#000",
            border: "1px solid #CCCDCF",
            borderRadius: "8px",
            padding: "8px 16px",
            fontFamily: "Helvetica",
            fontSize: "16px",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)",
          },
          success: {},
          error: {
            border: "1px solid rgba(255,0,0, 0.3)",
          },
        },
        showAddress: true,
        hideFields: [
          "address_line1",
          "address_line2",
          "address_city",
          "address_state",
          "address_country",
        ],
        submitLabel: buttonLabel,
        onLoad: () => setLoading(false),
        onSubmit: onSubmit,
      }

      const form: FenixForm = Finix.CardTokenForm("finix-form", options)
    }
  }, [isFinixScriptLoaded, fraudSessionId])

  return (
    <div className="relative">
      {isError && (
        <Alert>
          <div>We're sorry, but we weren't able to add your card.</div>
          <div>Please try again or use different card.</div>
        </Alert>
      )}
      <div id="finix-form" />
      {isLoading && (
        <div className="absolute left-0 top-0 right-0 bottom-0 overflow-hidden">
          <Code />
        </div>
      )}
      {(isProcessing || isDisabled) && (
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-white opacity-50"></div>
      )}
    </div>
  )
}

export default FinixPaymentForm
