import { Stepper } from "components/Stepper"
import React from "react"
import Header from "components/layout/Header"
import Footer from "components/layout/Footer"
import { ProviderLogo } from "types/asyncConsultation"

interface Props {
  children: React.ReactNode
  title?: string
  subTitle?: string
  description?: string
  selected: number
  providerLogo?: ProviderLogo
  totalLength?: number
}

export const AsyncConsultLayout: React.FC<Props> = ({
  children,
  title,
  description,
  selected,
  providerLogo,
  subTitle,
  totalLength = 0
}) => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex justify-center border-gray-200 bg-gray-50">
        <div className="w-[680px] py-11 mx-8">
          <Stepper length={totalLength + 2} selected={selected} />
          <Header
            title={title}
            description={description}
            providerLogo={providerLogo}
            subTitle={subTitle}
          />
          {children}
          <Footer />
        </div>
      </div>
    </div>
  )
}
