import useScript from "hooks/external/useScript"

const useLoadFinixScript = () => {
  const { isScriptLoaded } = useScript("https://js.finix.com/v/1/finix.js")

  return {
    isFinixScriptLoaded: isScriptLoaded,
  }
}

export default useLoadFinixScript