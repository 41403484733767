import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AsyncConsultLayout } from "layouts/AsyncConsultLayout"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "features/auth/authSlice"
import {
  Landing,
  FormBuilder,
  FormSubmit,
  AsyncConsultationErrorBoundary,
  SubmitSuccess,
} from "components/asyncConultation"
import { useForm } from "react-hook-form"
import { FormInitialValues, FormSubGroup } from "types/asyncConsultation"
import { useAsyncConsult } from "hooks/useAsyncConsult/useAsyncConsult"
import { WarningAlert } from "components/asyncConultation/WarningAlert"
import { FORM_LABELS } from "constants/asyncConsulationConstants"
import { Login } from "pages"
import {
  formatRegisterPayload,
  formatSubmitPayload,
} from "helpers/asyncConsultation"
import AccountInformation from "components/asyncConultation/AccountInformation"
import { useUpdateAccountMutation } from "app/services"
import { useSubmitFormMutation } from "app/services-v2/submitForm"
import { DisclaimerConsent } from "components/asyncConultation/DisclaimerConsent"
import { ProviderLicenseCheck } from "components/asyncConultation/ProviderLicenseCheck"

export const AsyncConsultationForm = () => {
  const [selected, setSelected] = useState<number>(0)
  const [isNotLicensed, setIsNotLicensed] = useState<boolean>(false)
  const [selectedState, setSelectedState] = useState<string>("")

  const user = useSelector(selectCurrentUser)
  const [
    updateAccount,
    {
      isSuccess,
      error: accountRegistrationError,
      isLoading: isAccountRegistrationLoading,
    },
  ] = useUpdateAccountMutation()

  const [
    handleSubmitForm,
    { isLoading: submittingForm, error: submitFormError },
  ] = useSubmitFormMutation()

  const params = useParams()
  const { asyncConsultationForm, error, providerFormId } = useAsyncConsult(
    params.slug as string,
  )

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    register,
    getValues,
    reset,
    formState: { errors },
    getFieldState,
    setValue,
  } = useForm<FormInitialValues>({
    defaultValues: asyncConsultationForm?.initialValues,
    mode: "all",
    shouldFocusError: false,
  })

  useEffect(() => {
    if (asyncConsultationForm?.initialValues) {
      reset(asyncConsultationForm.initialValues)
    }
  }, [asyncConsultationForm?.initialValues, reset])

  const handleNext = async (section: string) => {
    const isValid = await trigger(section)
    if (isValid) {
      localStorage.setItem(section, JSON.stringify(watch(section)))
      setSelected((prev) => prev + 1)
    }
  }

  const selectNextPage = () => {
    setSelected((prev) => prev + 1)
  }

  const handleRegisterAccount = async (code: string) => {
    const { payload, error: errorFromDataFormatting } = formatRegisterPayload(
      getValues("registration"),
      code,
    )
    if (payload === null) {
      console.error("error while formatting data", errorFromDataFormatting)
      return
    }
    await updateAccount(payload)
    if (isSuccess && !accountRegistrationError) {
      setSelected((prev) => prev + 1)
    }
    setSelected((prev) => prev + 1)
  }

  if (error) {
    return (
      <div>
        <AsyncConsultLayout
          selected={0}
          title={FORM_LABELS.error.title}
          description={FORM_LABELS.error.description}
          totalLength={0}
        >
          <AsyncConsultationErrorBoundary />
        </AsyncConsultLayout>
      </div>
    )
  }

  const ComponentMap = [
    {
      component: (
        <Landing
          handleButtonClick={selectNextPage}
          isNextDisabled={!!asyncConsultationForm?.sortedData?.length}
        />
      ),
      onClick: selectNextPage,
      title: "",
      description: "",
    },
    {
      component: (
        <ProviderLicenseCheck
          handleButtonClick={selectNextPage}
          licensedStatesSet={asyncConsultationForm?.licensedStatesSet}
          isNotLicensed={isNotLicensed}
          setIsNotLicensed={setIsNotLicensed}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
        />
      ),
      title: isNotLicensed
        ? "Your Provider Isn’t Licensed in Your State"
        : "Provider License Check",
      subTitle: "",
      description: isNotLicensed
        ? "We’d love to process your SmartConsult, but your selected provider isn’t licensed where you live."
        : "verify your provider's license and ensure eligibility for your SmartConsult.",
      onClick: selectNextPage,
    },
    ...(asyncConsultationForm?.sortedData.map(
      (subGroup: FormSubGroup, index) => ({
        component: (
          <FormBuilder
            formData={subGroup}
            control={control}
            register={register}
            watch={watch}
            getFieldState={getFieldState}
            errors={errors?.[subGroup.name]}
            buttonLabel="Next"
            handleButtonClick={() => handleNext(subGroup.name as string)}
          />
        ),
        title: subGroup.title,
        description: subGroup?.description,
        subTitle: subGroup.subTitle,
      }),
    ) || []),
    {
      component: (
        <DisclaimerConsent
          control={control}
          watch={watch}
          handleNext={selectNextPage}
        />
      ),
      title: FORM_LABELS.disclaimer.title,
      description: FORM_LABELS.disclaimer.description,
      subTitle: "",
    },
    {
      component: <Login hideLogo={true} handleNext={selectNextPage} />,
      title: "Ready to discover your treatment options?",
      subTitle: "",
      description:
        "Create an account or sign in to explore your treatment options.",
    },
    {
      component: (
        <AccountInformation
          register={register}
          control={control}
          handleButtonClick={handleRegisterAccount}
          isLoading={isAccountRegistrationLoading}
          setValue={setValue}
          errors={errors?.["registration"]}
          isValid={!getFieldState("registration").invalid}
          watch={watch}
          selectedState={selectedState}
        />
      ),
      title: FORM_LABELS.registration.title,
      description: FORM_LABELS.registration.description,
      subTitle: "",
    },
    {
      component: <FormSubmit isLoading={submittingForm} />,
      title: FORM_LABELS.formSubmit.title,
      description: FORM_LABELS.formSubmit.description,
    },
    {
      component: <SubmitSuccess />,
      title: FORM_LABELS.submitSuccess.title,
      description: FORM_LABELS.submitSuccess.description,
    },
  ]

  const onSubmit = async (data: FormInitialValues) => {
    if (!user) return
    const payload = formatSubmitPayload(data, user, providerFormId)
    await handleSubmitForm(payload)
    if (!submittingForm && !submitFormError) {
      setSelected((prev) => prev + 1)
    }
  }

  return (
    <div className="bg-gray-50">
      <form onSubmit={handleSubmit(onSubmit)}>
        <AsyncConsultLayout
          title={ComponentMap[selected]["title"]}
          description={ComponentMap[selected]["description"]}
          selected={selected}
          subTitle={ComponentMap[selected]?.subTitle}
          providerLogo={asyncConsultationForm?.providerLogo}
          totalLength={asyncConsultationForm?.sortedData.length}
        >
          {Object.keys(errors).length !== 0 &&
            ComponentMap[selected]["title"] !==
              FORM_LABELS.disclaimer.title && <WarningAlert />}
          {ComponentMap[selected]["component"]}
        </AsyncConsultLayout>
      </form>
    </div>
  )
}
