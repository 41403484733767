import Text from "components/text/Text"
import Button from "components/buttons/Button"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import { ReactComponent as SendIcon } from "assets/images/send.svg"

export const FormSubmit = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div className="flex gap-5 flex-col">
      <Text size="xl">
        Click ‘Submit’ to share your response with our medical team and
        begin your wellness journey.{" "}
      </Text>
      <Text size="xl">
        We'll review your information and reach out soon with personalized
        treatment options.
      </Text>
      <div className="flex justify-end mt-4">
        <div className="mb-6">
          <Button
            size="xs"
            type={"submit"}
            disabled={isLoading}
            icon={isLoading ? <LoadingSvg /> : <SendIcon />}
            isRounded
          >
            {false ? "Submitting Form..." : "Submit Form"}
          </Button>
        </div>
      </div>
    </div>
  )
}
