export const genderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
]

export const howMotivatedOptions = [
  { label: "Very motivated", value: "veryMotivated" },
  { label: "Somewhat motivated", value: "somewhatMotivated" },
  { label: "Not very motivated", value: "notMotivated" },
]

export const weightRelatedHealthChallengesOptions = [
  { label: "Less than 1 year", value: "lessThan1year" },
  {
    label: "1-5 years",
    value: "1-5Years",
  },
  { label: "More than 5 years", value: "moreThan%years" },
]

export const weightRelatedComplications = [
  { label: "Fatigue", value: "fatigue" },
  { label: "Joint pain", value: "jointPain" },
  {
    label: "Difficulty with physical activity",
    value: "difficultyWithPhysicalActivity",
  },
  { label: "Other", value: "other" },
]

export const primaryGoalForWeightLossOptions = [
  { label: "Reduce body weight", value: "reduceBodyWeight" },
  { label: "Improve overall health", value: "improveOverallHealth" },
  {
    label: "Manage obesity-related conditions",
    value: "manageObesityRelatedConditions",
  },
  { label: "Other", value: "other" },
]

export const generalConcernsOptions = [
  {
    label: "Difficulty losing or maintaining weight despite diet and exercise",
    value: "difficultyLosingWeight",
  },
  {
    label: "Changes in sexual health or performance",
    value: "changgeSexualHealth",
  },
  {
    label: "Thinning hair or hair loss",
    value: "thinningHair",
  },
  {
    label: "Low energy, fatigue, or poor sleep",
    value: "lowEnergy",
  },
  {
    label: "Mood changes or irritability",
    value: "moodChanges",
  },
  {
    label: "Memory and concentration difficulties",
    value: "memoryDifficulties",
  },
  {
    label: "Reduced muscle mass or strength",
    value: "reduceMuscleMass",
  },
  {
    label: "Interest in healthy aging and longevity",
    value: "interestInHealthyAging",
  },
  {
    label: "Changes in sex driver or libido",
    value: "changesInSexDriver",
  },
  {
    label: "Hot flashes or night sweats",
    value: "hotFlashes",
  },
  {
    label: "Wanting to feel more confident",
    value: "wantFeelConfident",
  },
  {
    label: "Bone and joint health concerns",
    value: "boneHealthConcerns",
  },
  {
    label: "Desire to maintain physical vitality",
    value: "physicalVitality",
  },
  {
    label: "Concerns about family health history",
    value: "concernFamilyHealthHistory",
  },
  {
    label: "Wanting to be more active",
    value: "wantBeActive",
  },
]
