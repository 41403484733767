import Text from "components/text/Text"
import Button from "components/buttons/Button"
import { useNavigate } from "react-router-dom"

export const DeactivationBanner = () => {
  const navigate = useNavigate()
  return (
    <>
      <div>
        <Text size="3xl" weight="bold" className="my-6" family="header">
          Your account has been disabled.
        </Text>
        <Text size="m" weight="normal" className="text-center" family="body">
          Please email feelgood@drwell.com if <br />
          you wish to reactivate your account.
        </Text>
      </div>
      <div className="mt-6 z-50 w-full">
        <Button onClick={() => navigate("/")}>Return to DrWell.com</Button>
      </div>
    </>
  )
}
