import { FC, ReactElement, useCallback, useState, useEffect } from "react"
import LoginConfirmationContent from "features/login/LoginConfirmationContent"
import LoginContent from "features/login/LoginContent"
import { User } from "types/entities/userTypes"
import useLogin from "hooks/auth/useLogin"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectRedirectAfterLogin } from "features/auth/authSlice"
import { ReactComponent as BgDecorativeSvg } from "assets/images/bg-decorative.svg"
import PoweredBy from "components/blocks/PoweredBy"
import useSignInPopup from "features/auth/useSignInPopup"
import { WellnessCodeRequest } from "types/authTypes"

interface IProps {
  inPopup?: boolean
  hideLogo?: boolean
  handleNext?: () => void
}

const Login: FC<IProps> = ({
  inPopup = false,
  hideLogo = false,
  handleNext,
}): ReactElement => {
  const [userId, setUserId] = useState<string>("")
  const [signInValue, setSignInValue] = useState<WellnessCodeRequest | null>(
    null,
  )

  const { hideSignIn, isSignInOpen } = useSignInPopup()
  const login = useLogin()
  const navigate = useNavigate()
  const redirectAfterLogin = useSelector(selectRedirectAfterLogin)
  const hideBackgound = !inPopup && !hideLogo

  const wrapperStyle = inPopup
    ? "md:w-[450px] flex flex-col items-center gap-1.31rem h-screen sm:h-auto"
    : hideLogo
      ? "flex items-start justify-center min gap-4 pt-12"
      : "flex items-start justify-center min-h-screen pt-24"

  const cancel = useCallback((): void => {
    setUserId("")
    setSignInValue(null)
  }, [])

  useEffect(() => {
    let mounted = true
    if (!isSignInOpen && mounted && inPopup) {
      cancel()
    }
    return () => {
      mounted = false
    }
  }, [isSignInOpen, cancel])

  const onSignIn = (token: string, user: User, code: string): void => {
    if (!userId) {
      return
    }
    login(token, user, code)

    if (handleNext) {
      handleNext()
      return
    }

    if (inPopup) {
      hideSignIn()
    }

    if (redirectAfterLogin) {
      navigate(redirectAfterLogin.path, { state: redirectAfterLogin.state })
    } else {
      navigate("/account")
    }
  }

  const displayLoginContent = (
    userId: string | undefined,
    inPopup: boolean,
  ): ReactElement => {
    if (userId) {
      return (
        <LoginConfirmationContent
          signInValue={signInValue}
          userId={userId}
          hideLogo={hideLogo}
          onSuccess={onSignIn}
          cancel={cancel}
          inPopup={inPopup}
        />
      )
    }

    return (
      <LoginContent
        setUserId={setUserId}
        setSignInValue={setSignInValue}
        hideLogo={hideLogo}
        inPopup={inPopup}
      />
    )
  }

  return (
    <main className={wrapperStyle}>
      {hideBackgound ? (
        <section className="absolute -top-36 overflow-hidden">
          <BgDecorativeSvg />
        </section>
      ) : null}
      <div
        className={`flex flex-col items-center justify-center gap-1.31rem relative mx-8 z-10 ${inPopup || hideLogo ? "sm:w-[30rem]" : "w-[34rem]"}`}
      >
        {displayLoginContent(userId, inPopup)}
      </div>
      {inPopup ? <PoweredBy /> : null}
    </main>
  )
}

export default Login
