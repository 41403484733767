import React, { Dispatch, FC, SetStateAction, useEffect } from "react"
import Title from "components/text/Title"
import Text from "components/text/Text"
import Button from "components/buttons/Button"
import Image from "components/images/Image"
import { ReactComponent as CreditCardRefreshIcon } from "assets/images/credit-card-refresh.svg"
import InfoBox from "components/ui/InfoBox"
import { useNavigate, useParams } from "react-router-dom"
import { getDrugImage, getFrequency } from "helpers/subscriptonHelpers"
import useSignInPopup from "features/auth/useSignInPopup"
import useAuth from "hooks/auth/useAuth"
import SubscriptionLoader from "features/subscriptions/SubscriptionLoader"
import { Navigate } from "react-router-dom"
import { Logo } from "types/entities/physicianLogo"
import useGetSubscriptionReferralLinkBySlug from "hooks/subscriptions/useGetSubscriptionReferralLinkBySlug"
import { ChangeProgramAlert } from "./ChanggeProgramAlert"

interface Props {
  setLogo: Dispatch<SetStateAction<Logo | null>>
}

const SubscriptionPage: FC<Props> = ({ setLogo }) => {
  const { slug } = useParams<{ slug: string }>()
  const navigate = useNavigate()
  const { showSignIn } = useSignInPopup()
  const user = useAuth()
  const { subscription, referralLink, invalid, isLoading } =
    useGetSubscriptionReferralLinkBySlug(slug || "")

  useEffect(() => {
    if (subscription?.physician?.logo) {
      setLogo(subscription.physician.logo)
    }
  }, [subscription, setLogo])

  if (invalid) {
    return <Navigate to="/not-found" relative="path" />
  }

  if (isLoading) {
    return <SubscriptionLoader />
  }

  const handleSubscriptionClick = () => {
    if (user) {
      return navigate("/checkout", {
        state: {
          subscription: subscription,
          referralLink: referralLink,
        },
      })
    }

    return showSignIn({
      path: "/checkout",
      state: { subscription: subscription, referralLink },
    })
  }

  const drugName = subscription?.drug?.name
  const drugImage = getDrugImage(drugName)

  const buttonText = referralLink?.previousSubscription ? "Confirm Your Subscription": "Start Your Subscription"

  return (
    <>
      {isLoading ? (
        <SubscriptionLoader />
      ) : (
        <main className="flex flex-col items-center justify-center py-10 px-4">
          <div className="w-full max-w-6xl flex flex-col md:flex-row items-center md:items-start px-4 md:px-0 mx-auto">
            <div className="block md:hidden">
              <ChangeProgramAlert referralLink={referralLink}  />
            </div>
            {drugImage ? (
              <Image
                src={drugImage}
                alt={`${drugName} Weight Loss Program`}
                className="max-w-sm h-auto rounded-lg"
              />
            ) : null}

            <div className="w-full md:w-1/2 lg:w-2/3 md:ml-8 mt-6 md:mt-0">
              <div className="hidden md:block">
                <ChangeProgramAlert referralLink={referralLink}/>
              </div>
              <Title family="header" weight="semibold">
                {subscription?.title}
              </Title>
              <InfoBox
                text="This product is available through a subscription"
                SvgIcon={CreditCardRefreshIcon}
                className="my-3"
              />
              <div className="flex items-center space-x-3 my-3">
                <Title family="header" size="s" weight="semibold">
                  ${subscription?.billing_cost}.00
                </Title>
                <Text family="body" color="grey" size="m">
                  {subscription ? "Billed " + getFrequency(subscription) : null}
                </Text>
              </div>

              <Button size="s" onClick={handleSubscriptionClick}>
                {buttonText}
              </Button>
            </div>
          </div>

          <div className="w-full max-w-6xl flex flex-col md:flex-row items-center md:items-start px-4 md:px-0 mx-auto my-8">
            <Text color="grey" size="l">
              {subscription?.description}
            </Text>
          </div>
        </main>
      )}
    </>
  )
}

export default SubscriptionPage
