import { CheckboxGroup } from "components/inputs/CheckboxGroups"
import RadioGroup from "components/inputs/RadioGroup"
import TextAreaInput from "components/inputs/TextAreaInput"
import TextInput from "components/inputs/TextInput"
import { FC } from "react"
import {
  Control,
  Controller,
  FieldError,
  UseFormRegister,
} from "react-hook-form"
import { FormInitialValues, FormSubGroupSection } from "types/asyncConsultation"

interface CustomFormFieldProps {
  data: FormSubGroupSection
  control: Control<FormInitialValues, any>
  register: UseFormRegister<FormInitialValues>
  errors?: { [key: string]: FieldError }
  name: string
}

const OptionalInputIds = [
  "740132f8-3398-4853-af5a-15367d4c0ace",
  "7c361c87-c9c5-4916-a204-78b09ea14cfa",
  "6d4bf1df-4802-44b5-8a75-6ea6247e63da",
]

type conditionalInputField = {
  id: string
  conditionalValue: string
  title: string
}

export const CustomFormField: FC<CustomFormFieldProps> = ({
  data,
  control,
  register,
  name: formDataName,
  errors,
}) => {
  if (!data?.isEnabled) {
    return null
  }

  const type = data.formInputs[0].type
  if (type === "checkbox") {
    const conditionalFormMap = data?.formInputs
    ?.filter((input) => input.isConditional)
    .reduce(
      (acc, input) => {
        if (!input.conditionalInputId) return acc
        acc[input.conditionalInputId] = {
          id: input.id,
          conditionalValue: input.conditionalInputValue,
          title: input.title,
        }
        return acc
      },
      {} as Record<string, conditionalInputField>,
    )

    const options = data.formInputs
      .filter((o) => !o.isConditional && o.isEnabled)
      .map((o) => ({
        label: o.title,
        value: o.id,
        conditionalForm: conditionalFormMap[o.id],
      }))


    const rules = OptionalInputIds.includes(data.id)
      ? { required: false }
      : { required: "Please select at least one option" }

    return (
      <Controller
        name={`${formDataName}.${data.id}`}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          return (
            <CheckboxGroup
              options={options}
              selectedOption={value as string[]}
              onChange={onChange}
              errors={errors}
              dataId={data.id}
              register={register}
              label={data.name}
              formDataName={formDataName}
            />
          )
        }}
      />
    )
  }

  if (type === "text") {
    return (
      <TextInput
        label="Please specify:*"
        className="rounded-md"
        placeholder="Enter details"
        inputSize="s"
        {...register(`${formDataName}.${data.name}`, {
          required: "Required",
        })}
        error={errors?.[data.id].message}
      />
    )
  }

  if (type === "radio") {
    const conditionalFormMap = data?.formInputs
    ?.filter((input) => input.isConditional)
    .reduce(
      (acc, input) => {
        if (!input.conditionalInputId) return acc
        acc[input.conditionalInputId] = {
          id: input.id,
          conditionalValue: input.conditionalInputValue,
          title: input.title,
        }
        return acc
      },
      {} as Record<string, conditionalInputField>,
    )

    const options = data.formInputs
      .filter((o) => !o.isConditional && o.isEnabled)
      .map((o) => ({
        label: o.title,
        value: o.id,
        conditionalForm: conditionalFormMap[o.id],
      }))

    const rules = OptionalInputIds.includes(data.id)
      ? { required: false }
      : { required: "Please select an option" }

    return (
      <Controller
        name={`${formDataName}.${data.id}`}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          return (
            <RadioGroup
              options={options}
              selectedOption={value as any}
              onChange={onChange}
              label={data.name}
              errors={errors}
              register={register}
              dataId={data.id}
              formDataName={formDataName}
            />
          )
        }}
      />
    )
  }

  if (type === "textarea") {
    const name = `${formDataName}.${data.formInputs[0].id}`
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: "Please write down your answer",
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <TextAreaInput
              label={data.name ?? ""}
              placeholder={data.formInputs[0].title ?? ""}
              errorMessage={error?.message}
              value={value}
              onChange={onChange}
            />
          )
        }}
      />
    )
  }

  return <></>
}
