import { format, getYear, isAfter, isValid, parse, parseISO } from "date-fns";
import { DATE_FORMAT_DEFAULT } from "../dateTimeUtils";
import * as yup from "yup";

/**
 * Error messages
 */
export const errorInvalidBirthDateFormatMMDDYYYYRequired = "Invalid date of birth format (MM/DD/YYYY required)";
export const errorBirthDateCannotBePriorTo1900 = "Date of birth cannot be prior to 1900";
export const errorBirthDateCannotBeInTheFuture = "Date of birth cannot be in the future";

/**
 * Yup Validation schema for date of birth.
 *
 * @returns {yup.MixedSchema} - The validation schema.
 */
export const getYupBirthDateValidationRules = () =>
  yup.mixed()
    .required("Date of birth is required")
    .test("date-valid-format", errorInvalidBirthDateFormatMMDDYYYYRequired, isBirthDateValidFormat)
    .test("date-in-future", errorBirthDateCannotBeInTheFuture, isBirthDateInPast)
    .test("date-too-old", errorBirthDateCannotBePriorTo1900, isBirthDateInAgeRange);

/**
 * Parses the given value into a Date object.
 *
 * @param value - The value to parse.
 * @returns {Date | null} - The parsed Date object or null if invalid.
 */
const parseDate = (value: unknown): Date | null => {
  if (value instanceof Date && !isNaN(value.getTime())) return value;
  if (typeof value === "string") return value.includes("T") ? parseISO(value) : parse(value, DATE_FORMAT_DEFAULT, new Date());

  return null;
};

/**
 * Checks if the given date is valid and formatted as MM/DD/YYYY
 *
 * @param value - The date value to validate
 * @returns {boolean}
 */
export const isBirthDateValidFormat = (value: unknown): boolean => {
  const parsedDate = parseDate(value);
  if (!parsedDate) return false;
  const formattedValue = format(parsedDate, DATE_FORMAT_DEFAULT);
  const year = getYear(parse(formattedValue, DATE_FORMAT_DEFAULT, new Date()));

  return year >= 1000 && isValid(parsedDate);
};

/**
 * Checks if the given date is in the past.
 *
 * @param value - The date value to validate
 * @returns {boolean}
 */
export const isBirthDateInPast = (value: unknown): boolean => {
  const parsedDate = parseDate(value);

  return parsedDate ? !isAfter(parsedDate, new Date()) : false;
};

/**
 * Checks if the given date is in the age range of 1900 or later.
 *
 * @param value - The date value to validate
 * @return {boolean}
 */
export const isBirthDateInAgeRange = (value: unknown): boolean => {
  const parsedDate = parseDate(value);

  return parsedDate ? getYear(parsedDate) >= 1900 && isValid(parsedDate) : false;
};

/**
 * Validates the date of birth.
 *
 * @param value - The date value to validate
 * @returns {boolean | string} - True if valid, otherwise an error message
 */
export function isValidDOB(value: string | string[]): boolean | string {
  const enteredDate = new Date(Array.isArray(value) ? value[0] : value);
  if (!isBirthDateInPast(enteredDate)) return errorBirthDateCannotBeInTheFuture;
  if (!isBirthDateInAgeRange(enteredDate)) return errorBirthDateCannotBePriorTo1900;
  if (!isBirthDateValidFormat(enteredDate)) return errorInvalidBirthDateFormatMMDDYYYYRequired;

  return true;
}

export const accountInformationRules = {
  nameFirst: {
    required: "First name is required",
  },
  nameLast: {
    required: "Last name is required",
  },
  birthDate: {
    required: "Birthday is required",
    validate: isValidDOB,
  },
  gender: {
    required: "Gender is required",
  },
  phone: {
    required: "Phone number is required",
    pattern: {
      value: /^\(\d{3}\) \d{3}-\d{4}$/,
      message: "Phone number must be exactly 10 digits",
    },
  },
  email: {
    required: "Email is requried",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Please enter a valid email address",
    },
  },
  state: { required: "Please select state" },
  weight: {
    required: "Weight is required",
    max: {
      value: 999,
      message: "Weight must be less than or equal to 999",
    },
    min: {
      value: 0,
      message: "Weight must be greater than 0",
    },
  },
  heightFeet: {
    required: "Height is required",
    min: {
      value: 0,
      message: "Value must be great than 0",
    },
    max: {
      value: 9,
      message: "Value must be less than 10",
    }
  },
  heightInches: {
    required: "This field is required",
    max: {
      value: 11,
      message: "Value must be less than 11",
    },
    min: {
      value: 0,
      message: "Value must be great than 0",
    },
  },
}
