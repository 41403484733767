import { FC, ReactElement } from "react"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import { WellnessCodeRequest } from "types/authTypes"
import OtpInput from "components/inputs/OtpInput"
import Button from "components/buttons/Button"
import { User } from "types/entities/userTypes"
import TextButton from "components/buttons/TextButton"
import InputError from "components/inputs/InputError"
import useInputWellnessCode from "features/auth/useInputWellnessCode"
import useWellnessCodeForm from "features/wellnesscode/useWellnessCodeForm"
import ResendCode from "features/login/ResendCode"
import BrandHeader from "features/login/BrandHeader"
import RightIcon from "assets/images/RightIcon"

interface IProps {
  userId: string
  onSuccess: (token: string, user: User, code: string) => void
  cancel: () => void
  inPopup: boolean
  signInValue: WellnessCodeRequest | null
  hideLogo?: boolean
}

const LoginConfirmationContent: FC<IProps> = ({
  userId,
  onSuccess,
  cancel,
  inPopup,
  signInValue,
  hideLogo,
}): ReactElement => {
  const { handleSumbit, isLoading, error } = useInputWellnessCode({
    userId,
    onSuccess,
  })

  const { handleInputChange, handleSubmit, isSubmitDisabled } =
    useWellnessCodeForm({ onSubmit: handleSumbit })

  return (
    <>
      <BrandHeader
        isLogoVisible={!inPopup}
        hideLogo={hideLogo}
        body="Your personal wellness key is on the way!"
        description={
          <section className="font-body text-center">
            Enter the code sent to <b>{signInValue ? signInValue.value : ""}</b>{" "}
            <br />
            to unlock your wellness journey.
          </section>
        }
      />
      <form className="flex flex-col items-center w-full gap-1.31rem z-10">
        <section className="w-full">
          <OtpInput className="h-20 max-md:h-16" onChange={handleInputChange} />
          {error ? <InputError>{error}</InputError> : null}
        </section>
        <ResendCode signInValue={signInValue} />
        <section
          className={`flex flex-col ${hideLogo ? "items-end mt-6" : "items-center"} w-full`}
        >
          <Button
            type="submit"
            disabled={isLoading || isSubmitDisabled}
            onClick={handleSubmit}
            fullWidth={!hideLogo}
            icon={
              isLoading ? (
                <LoadingSvg />
              ) : (
                <RightIcon disabled={isLoading || isSubmitDisabled} />
              )
            }
            size={hideLogo ? "xs" : "m"}
            isRounded={hideLogo}
            className={hideLogo ? "w-56" : ""}
          >
            {isLoading ? "Please wait..." : "Unlock my journey"}
          </Button>
          {!hideLogo && (
            <TextButton onClick={cancel} className="mt-10">
              Cancel
            </TextButton>
          )}
        </section>
      </form>
    </>
  )
}

export default LoginConfirmationContent
