import { RichLogo } from "components/RichLogo"
import { ReactComponent as BgDecorativeSvg } from "assets/images/bg-decorative.svg"
import { DeactivationBanner } from "components/DeactivationBanner/DeactivationBanner"

export const DeactivationSuccess = () => {
  return (
    <div className="relative flex flex-col items-center">
      <div className="absolute top-0 left-0 right-0 h-[70%] overflow-hidden">
        <BgDecorativeSvg />
      </div>
      <div>
        <div className="mt-36">
          <RichLogo />
        </div>
        <DeactivationBanner/>
      </div>
    </div>
  )
}
