import { useAppDispatch } from "app/hooks"
import { setCredentials } from "helpers/authHelpers"
import { setUser } from "features/auth/authSlice"
import { User } from "types/entities/userTypes"

const useLogin = () => {
  const dispatch = useAppDispatch()

  const login = (token: string, user: User, code: string) => {
    setCredentials(token, user)
    dispatch(setUser({...user, wellness_key: code}))
  }

  return login
}

export default useLogin
