export function capitalizeFirstChar(str: string | undefined) {
  if (!str) return str

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function convertIncheToFeetandInch(inches: number) {
  const feet = Math.floor(inches / 12); 
  const remainingInches = (inches % 12).toFixed(2);
  return { feet, inches: remainingInches };
}