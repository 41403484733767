import { FC, memo, ReactElement } from "react"
import { RichLogo } from "components/RichLogo"

interface IProps {
  isLogoVisible: boolean
  body: string
  description: ReactElement
  hideLogo?: boolean
}

const BrandHeader: FC<IProps> = memo(
  ({
    body,
    description,
    isLogoVisible = true,
    hideLogo = false,
  }): ReactElement => {
    return (
      <>
        {!hideLogo && (
          <>
            {isLogoVisible ? <RichLogo /> : null}
            <p className="text-3xl text-center font-semibold font-header accent-black-primary z-10">
              {body}
            </p>
          </>
        )}
        {description}
      </>
    )
  },
)

export default BrandHeader
