import { useState, useEffect } from "react"

interface UseScriptResult {
  isScriptLoaded: boolean
}

const useScript = (src: string): UseScriptResult => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = src
    script.async = true

    document.body.appendChild(script)

    script.onload = () => setIsLoaded(true)
  }, [src])

  return { isScriptLoaded: isLoaded }
}

export default useScript