import { useMemo } from "react"
import { useGetProviderFormQuery } from "app/services-v2/asyncConsultFormApi"
import { Form, FormInitialValues, FormSubGroup } from "types/asyncConsultation"

enum RELATION_TYPE {
  PARENT = "PARENT",
  CHILD = "CHILD",
  DEFAULT = "DEFAULT",
}

const generateFormInitialValues = (form: Form): FormInitialValues => {
  const initialValues = {
    registration: {
      name_first: "",
      name_last: "",
      birth_date: "",
      email: "",
      phone: "",
      gender_assigned_at_birth: "Female",
      address_state: "",
      weight: 0,
      height_feet: 0,
      height_inches: 0,
    },
    disclaimer: {
      confirmAccuration: "",
      consent: "",
    },
  } as any

  form.formSubGroups.forEach((subGroup) => {
    initialValues[subGroup.name] = {}

    if (subGroup.name === "General Concerns") {
      initialValues[subGroup.name] = {
        [subGroup.id]: [],
      }
      return
    }

    subGroup.formSubGroupSections.forEach((section) => {
      const hasCheckboxes = section.formInputs.some(
        (input) => input.type === "checkbox",
      )
      const hasTextArea =
        section.formInputs.length === 1 &&
        section.formInputs[0]?.type === "textarea"
      if (hasCheckboxes) {
        initialValues[subGroup.name][section.formInputs[0].id] = []
      } else {
        if (hasTextArea) {
          initialValues[subGroup.name][section.formInputs[0].id] = ""
        } else {
          initialValues[subGroup.name][section.id] = ""
        }
      }

      section.formInputs.forEach((input) => {
        if (input.isConditional && input.type === "text") {
          initialValues[subGroup.name][input.id] = ""
        }
      })
    })
  })

  return initialValues
}

export const useAsyncConsult = (slug: string) => {
  const { data, error } = useGetProviderFormQuery(slug)

  const asyncConsultationForm = useMemo(() => {
    if (!data || !data.isEnabled) return null
    if (!data.form || !data.providerFormSubGroups) return null
    const enabledMap = new Map<string, boolean>()
    const parentFormSubGroupSortOrder: Record<string, number> = {}
    data.providerFormSubGroups.forEach((subGroup) => {
      if (!subGroup.deletedDate && subGroup.formSubGroup)
        enabledMap.set(subGroup.formSubGroup.id, subGroup.isEnabled)
      if (subGroup.formSubGroup) {
        switch (subGroup.formSubGroup.relationType) {
          case RELATION_TYPE.DEFAULT:
            parentFormSubGroupSortOrder[subGroup.formSubGroup.id] =
            subGroup.formSubGroup.sortOrder
          break
        case RELATION_TYPE.PARENT:
          parentFormSubGroupSortOrder[subGroup.formSubGroup.id] =
            subGroup.formSubGroup.sortOrder * 1000
          break
        }
      }
    })

    /* This is O(1) operation, no need to cache.
               Actual sort order is 
       default subgroups have 3 digits number
       parent subgroups have 6 digits number ends with 000
       child subgroups have 6 digits number (`parent sort order` + `child sort order`)
    */

    const getActualSortOrder = (subGroup: FormSubGroup): number => {
      if (subGroup.parentId) {
        return (
          Number(parentFormSubGroupSortOrder[subGroup.parentId]) +
          subGroup.sortOrder
        )
      }
      return parentFormSubGroupSortOrder[subGroup.id] || subGroup.sortOrder
    }

    const providerLogo = data?.provider?.logo
    const sortedData = [...data.form.formSubGroups]
    // Enabled map means enabled by provider, isEnabled means enabled by our system.
      .filter((subField) => enabledMap.get(subField.id) && subField.isEnabled)
      .sort((a, b) => getActualSortOrder(a) - getActualSortOrder(b))

    const licensedStates = data?.provider?.licensedStates ?? [];
    const licensedStatesSet = new Set<string>();
    licensedStates.forEach((state) => {
      licensedStatesSet.add(state.state);
    });
    
    return {
      initialValues: generateFormInitialValues(data?.form),
      sortedData,
      providerLogo,
      licensedStatesSet,
    }
  }, [data])

  return { asyncConsultationForm, error, providerFormId: data?.id || "", }
}
