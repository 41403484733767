type Option = {
  label: string
  value: string
}
interface ButtonGroupProps {
  value: string
  onChange: (value: string) => void
  options: Option[]
  error?: string
}

const ButtonGroup:React.FC<ButtonGroupProps> = 
  ({ value, onChange, error, options }) => {
    return (
      <div className="relative border-[#E4E7EC] border-solid rounded-[0.625rem] bg-white border-2 z-50">
        <div className="flex">
          {options.map((option, index) => (
            <button
              key={`${option.value}.${index}`}
              onClick={() => onChange(option.value)}
              type='button'
              className={`cursor-pointer relative transition rounded-[0.625rem] px-[1.375rem]
                 py-[0.75rem] font-semibold text-[1.125rem] leading-[1.5rem] inline-flex 
                 items-center justify-center gap-[0.62rem] duration-200 ease-linear w-full
                 focus:outline-none${
                   value === option.value
                     ? "bg-blue-500 text-[#344054] border-[#E4E7EC] border-r border-solid shadow-md"
                     : "text-black bg-gray-100"
                 }`}
            >
              {option.label}
            </button>
          ))}
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>
    )
  }

export default ButtonGroup
