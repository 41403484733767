import { useEffect, useState } from "react"

declare const Finix: {
  Auth: (
    environment: string,
    applicationId: string,
    callback: (fraudSessionId: string) => void,
  ) => any
}

const useGetFinixFraudSessionId = (isFinixScriptLoaded: boolean) => {
  const [fraudSessionId, setFraudSessionId] = useState<string>()

  useEffect(() => {
    if (isFinixScriptLoaded) {
      Finix.Auth(
        process.env.REACT_APP_FINIX_ENVIRONMENT as string,
        process.env.REACT_APP_FINIX_MERCHANT_ID as string,
        (id) => {
          setFraudSessionId(id)
        },
      )
    }
  }, [isFinixScriptLoaded])

  return fraudSessionId
}

export default useGetFinixFraudSessionId