import Text from "components/text/Text"
import { Alert } from "components/alert/Alert"
import { SubscriptionReferralLink } from "types/entities/subscriptionTypes"
import { formatPhoneNumber } from "utils/phoneUtils"
import React from "react"

interface Props {
  referralLink?: SubscriptionReferralLink
}

export const ChangeProgramAlert: React.FC<Props> = ({ referralLink }) => {
  if (!referralLink?.previousSubscription) {
    return null
  }

  const providerPhone = formatPhoneNumber(referralLink?.physician?.phone)
  const changeReason = referralLink?.changeReason
    ? `Reason for recommendation: ${referralLink.changeReason}`
    : "This program has been recommended for you"

  return (
    <div className="mb-8">
      <Alert>
        <div className="pt-3 pl-4 text-[#475467]">
          <Text size="m" weight="bold" color="grey" className="mb-2">
            Important Update to Your Subscription
          </Text>
          <Text size="m" className="mb-4" color="grey">
            You are currently enrolled in the{" "}
            <span className="font-semibold">
              {referralLink?.previousSubscription?.title}
            </span>
            . However, your provider has updated your subscription to the
            program shown on this page for the following reason:
          </Text>
          <Text size="m" weight="bold" color="grey" className="mb-2">
            {changeReason}
          </Text>
          <Text size="m" className="mb-4" color="grey">
            Please complete the checkout process to acknowledge and accept this
            change. Delayed action may disrupt your care.
          </Text>
          {!!providerPhone && (
            <Text color="grey">
              If you have any questions or concerns, please contact our practice
              at {providerPhone}.
            </Text>
          )}
        </div>
      </Alert>
    </div>
  )
}
