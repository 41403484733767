import { apiV2 } from "./apiV2"
import { AsyncConsultForm } from "../../types/asyncConsultation"
export const providerFormApi = apiV2.injectEndpoints({
  endpoints: (build) => {
    return {
      getProviderForm: build.query<AsyncConsultForm, string>({
        query: (slug) => `provider-form/${slug}`,
      }),
    }
  },
})
export const { useGetProviderFormQuery } = providerFormApi
