import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_V2_URL,
  prepareHeaders: (headers) => {
    headers.set("Accept", `application/json`)
    return headers
  },
})
export const apiV2 = createApi({
  reducerPath: "apiV2",
  baseQuery: baseQuery,
  endpoints: () => ({}),
})
