import React from "react"
import InputLabel from "./InputLabel"
import { Checkbox } from "./Checkbox"
import TextInput from "./TextInput"
import { FormInitialValues } from "types/asyncConsultation"
import { FieldError, UseFormRegister } from "react-hook-form"

type Option = {
  label: string
  value: string
  conditionalForm?: {
    id: string
    conditionalValue: string
    title: string
  }
}

interface Props {
  options: Option[]
  selectedOption: string[]
  onChange: (selected: string[] | string) => void
  errors?: { [key: string]: FieldError }
  label?: string
  register?: UseFormRegister<FormInitialValues>
  formDataName?: string
  dataId?: string
}

export const CheckboxGroup: React.FC<Props> = ({
  options,
  selectedOption,
  onChange,
  errors,
  label,
  dataId = "",
  register,
  formDataName,
}) => {
  const handleCheckboxChange = (option: string) => {
    if (selectedOption) {
      if (selectedOption.includes(option)) {
        onChange(selectedOption.filter((item) => item !== option))
      } else {
        onChange([...selectedOption, option])
      }
    } else {
      onChange([option])
    }
  }

  return (
    <div className="w-full mx-auto">
      {label && <InputLabel size="lg">{label}</InputLabel>}
      <div className="space-y-6">
        {options.map((option, index) => {
          const isChecked = Array.isArray(selectedOption)
            ? selectedOption.includes(option.value)
            : selectedOption === option.value
          return (
            <>
              <Checkbox
                key={index}
              label={option.label}
              checked={isChecked}
              onChange={() => handleCheckboxChange(option.value)}
              error={!!errors?.[dataId]}
            />
            {isChecked && option.conditionalForm && register && (
              <TextInput
                label={`${option.conditionalForm.title}:*`}
                className="rounded-md"
                placeholder="Enter details"
                inputSize="s"
                {...register(`${formDataName}.${option.conditionalForm.id}`, {
                  required: true,
                })}
                error={!!errors?.[option.conditionalForm.id as string]}
              />
            )}
            </>
          )
        })}
      </div>
    </div>
  )
}
