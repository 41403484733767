import { useState, Dispatch, SetStateAction, useCallback } from "react"
import RightIcon from "assets/images/RightIcon"
import Button from "components/buttons/Button"
import Text from "components/text/Text"
import { ReactComponent as Loader } from "assets/images/loading-icon.svg"
import SelectInput from "components/inputs/SelectInput"
import { stateOptions } from "constants/core/stateConstants"

interface Props {
  handleButtonClick: () => void
  licensedStatesSet: Set<string> | undefined
  isNotLicensed: boolean
  setIsNotLicensed: Dispatch<SetStateAction<boolean>>
  selectedState: string
  setSelectedState: Dispatch<SetStateAction<string>>
}

const description = [
  <Text size="xl" family="body">
    To ensure you&apos;re matched with a licensed provider, please select the
    state you reside in. If your provider isn&apos;t licensed in your state,
    we&apos;ll connect you with one who can seamlessly process your SmartConsult
    form.
  </Text>,
  <Text size="xl" family="body">
    No worries — we&apos;ll get you back on track! Click the button below to
    visit the DrWell Provider Finder, where we&apos;ll help you connect with a
    licensed provider in your state so you can continue your SmartConsult
    seamlessly. Thanks for your patience — we&apos;re here to help!
  </Text>,
]

export const ProviderLicenseCheck: React.FC<Props> = ({
  handleButtonClick,
  licensedStatesSet,
  isNotLicensed,
  setIsNotLicensed,
  selectedState,
  setSelectedState,
}) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)

    if (licensedStatesSet?.has(selectedState)) {
      handleButtonClick()
    } else {
      setIsNotLicensed(true)
    }
    setLoading(false)
  }

  const handleFindProvider = useCallback(async () => {
    setLoading(true)
    window.location.href = `https://www.drwell.com/find-a-provider/`
  }, [selectedState, setLoading])

  return (
    <div className="min-h-[calc(100vh-400px)]">
      {isNotLicensed ? description[1] : description[0]}
      {isNotLicensed ? (
        <div className="mt-6 justify-center flex">
          <Button
            size="xs"
            className="rounded-full w-full sm:w-1/2 min-w-1/2 text-xs sm:text-sm md:text-base lg:text-lg "
            onClick={handleFindProvider}
          >
            Find a Provider
          </Button>
        </div>
      ) : (
        <>
          <div className="w-full mt-6">
            <SelectInput
              label="Residing state"
              options={stateOptions.map((option) => ({
                value: option.label.split(" - ")[1],
                label: option.label.split(" - ")[1],
              }))}
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value)
              }}
              error={""}
            />
          </div>
          <div className="flex justify-end mt-12">
            <div className="mb-4">
              <Button
                size="xs"
                onClick={handleClick}
                icon={loading ? <Loader /> : <RightIcon disabled={loading} />}
                isRounded
                disabled={loading || !selectedState.trim()}
              >
                {loading ? "Please wait..." : "Continue"}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
