import React from "react"
import { ReactComponent as AlertIcon } from "assets/images/alert-circle.svg"

interface Props {
  children: React.ReactNode
}

export const Alert: React.FC<Props> = ({ children }) => {
  return (
    <div className="bg-[#FFE9C5] border-[#F9B655] rounded-lg p-3 flex border-solid border">
      <div>
        <AlertIcon/>
      </div>
      <div>{children}</div>
    </div>
  )
}
