import { Control, Controller, UseFormWatch } from "react-hook-form"
import { CheckboxGroup } from "components/inputs/CheckboxGroups"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import RightIcon from "assets/images/RightIcon"
import { FormInitialValues } from "types/asyncConsultation"
import Button from "components/buttons/Button"

const CONFIRM_ACCURATION_OPTION = [
  {
    label: "I confirm that all my answers are accurate and complete",
    value: "confirm",
  },
]

const CONFIRM_CONSENT_OPTION = [
  {
    label:
      "I understand that my information will be reviewed by a licensed medical provider.",
    value: "confirm",
  },
]

interface Props {
  control: Control<FormInitialValues, any>
  watch: UseFormWatch<FormInitialValues>
  handleNext: () => void
}

export const DisclaimerConsent: React.FC<Props> = ({
  control,
  watch,
  handleNext,
}) => {
  const disclaimerWatch = watch("disclaimer")

  const isButtonEnabled = Object.values(disclaimerWatch).every(
    (v) => v?.length > 0,
  )

  return (
    <>
      <div className="flex flex-col gap-12">
        <Controller
          name={"disclaimer.confirmAccuration"}
          control={control}
          rules={{ required: "Please select at least one option" }}
          render={({ field: { value, onChange } }) => {
            return (
              <CheckboxGroup
                options={CONFIRM_ACCURATION_OPTION}
                selectedOption={value as string[]}
                onChange={onChange}
                label={
                  "By submitting this questionnaire, you confirm that the information provided is accurate to the best of your knowledge"
                }
              />
            )
          }}
        />
        <Controller
          name={"disclaimer.consent"}
          control={control}
          rules={{ required: "Please select at least one option" }}
          render={({ field: { value, onChange } }) => {
            return (
              <CheckboxGroup
                options={CONFIRM_CONSENT_OPTION}
                selectedOption={value as string[]}
                onChange={onChange}
                label={
                  "Do you consent to this information being reviewed by a licensed medical professional to determine your eligibility for care?"
                }
              />
            )
          }}
        />
      </div>
      <div className="flex justify-end mt-12">
        <div>
          <Button
            size="xs"
            type={"button"}
            onClick={handleNext}
            icon={
              false ? <LoadingSvg /> : <RightIcon disabled={!isButtonEnabled} />
            }
            disabled={!isButtonEnabled}
            isRounded
          >
            {false ? "Please wait..." : "Next"}
          </Button>
        </div>
      </div>
    </>
  )
}
