import { User } from "types/entities/userTypes"
import { api } from "app/services/api"
import { WellnessCodeRequest } from "types/authTypes"
import { entityId } from "types/core/basicEntityTypes"

export const usersApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      sendWellnessCode: build.mutation<User, entityId & WellnessCodeRequest>({
        query: ({ id, ...body }) => ({
          url: `users/${id}/send-wellness-code`,
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "content-type": "application/ld+json",
          },
        }),
      }),
      deactivateAccount: build.mutation<User, string>({
        query: (id) => ({
          url: `users/${id}`,
          method: "DELETE",
        }),
      }),
    }
  },
})

export const { useSendWellnessCodeMutation, useDeactivateAccountMutation } = usersApi
