import { useState } from "react"
import TextButton from "components/buttons/TextButton"
import Popup from "components/popups/Popup"
import Text from "components/text/Text"
import Button from "components/buttons/Button"
import { ReactComponent as AlertIcon } from "assets/images/info-circle.svg"
import { useDeactivateAccountMutation } from "app/services"
import { useAppSelector } from "app/hooks"
import { selectCurrentUser } from "features/auth/authSlice"
import { useNavigate } from "react-router-dom"
import { clearCredentials } from "helpers/authHelpers"

export const DeactivateAccount = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean>(false)

  return (
    <>
      <div className="mt-8">
        <TextButton className="text-red" onClick={() => setIsOpen(true)}>
          Deactivate Account
        </TextButton>
      </div>
      <DeactivateAccountPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isErrorPopupOpen={isErrorPopupOpen}
        setIsErrorPopupOpen={setIsErrorPopupOpen}
      />
      <ErrorPopup isOpen={isErrorPopupOpen} setIsOpen={setIsErrorPopupOpen} />
    </>
  )
}

const InfoIcon = () => (
  <div className="absolute top-[-38px] left-[-14px]">
    <div className="p-3 border border-[#FEF0C7] rounded-full">
      <div className="bg-[#FEF0C7] rounded-full p-3">
        <AlertIcon />
      </div>
    </div>
  </div>
)

const DeactivateAccountPopup = ({
  isOpen,
  setIsOpen,
  setIsErrorPopupOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isErrorPopupOpen: boolean
  setIsErrorPopupOpen: (isErrorPopupOpen: boolean) => void
}) => {
  const [deactivateAccount, { isLoading }] =
    useDeactivateAccountMutation()
  const user = useAppSelector(selectCurrentUser)
  const navigate = useNavigate()
  const handleClick = async () => {
    try {
      const response = await deactivateAccount(user?.id as string)
      if (response?.error) {
        setIsErrorPopupOpen(true)
        setIsOpen(false)
        return
      }
      clearCredentials()
      navigate("/deactivation-success")
    } catch (error) {
      setIsErrorPopupOpen(true)
      setIsOpen(false)
      return
    }
  }

  return (
    <Popup isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="relative pt-8">
        <InfoIcon />
        <Text size="xl" weight="semibold" className="text-start mb-2">
          Deactivate Account
        </Text>
        <div className="mb-8">
          <Text color="grey" className="text-start">
            Important Notice: If you have any previous medical
          </Text>
          <Text color="grey" className="text-start">
            history, those records cannot be deleted and will
          </Text>
          <Text color="grey" className="text-start">
            remain with your healthcare provider. However, you will
          </Text>
          <Text color="grey" className="text-start">
            no longer have access to your DrWell.com account.
          </Text>
        </div>
        <div className="flex gap-4">
          <Button color="white" onClick={() => setIsOpen(false)} size="xs">
            Cancel
          </Button>
          <Button size="xs" onClick={handleClick} disabled={isLoading}>
            Deactivate
          </Button>
        </div>
      </div>
    </Popup>
  )
}

const ErrorPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  return (
    <Popup isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="relative pt-8">
        <InfoIcon />
        <Text size="xl" weight="semibold" className="text-start mb-2">
          Unable to Deactivate Your Account
        </Text>
        <div className="mb-8">
          <Text color="grey" className="text-start mb-3">
            We’re sorry, but we cannot deactivate your DrWell.com <br /> account
            because you have active subscriptions.
          </Text>
          <Text color="grey" className="text-start mb-3">
            To proceed with deactivation, please cancel all active <br />{" "}
            subscriptions first. Once your subscriptions are <br /> canceled,
            you can request account deactivation again
          </Text>
          <Text color="grey" className="text-start mb-3">
            If you need assistance, please contact us at <br />{" "}
            feelgood@drwell.com.
          </Text>
        </div>
        <div className="flex gap-4">
          <Button onClick={() => setIsOpen(false)} size="xs">
            Got It
          </Button>
        </div>
      </div>
    </Popup>
  )
}
