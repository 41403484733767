import React from "react"
import InputLabel from "./InputLabel"
import { Radio, RadioOption } from "./Radio"
import { FieldError, UseFormRegister } from "react-hook-form"
import { FormInitialValues } from "types/asyncConsultation"

interface Props {
  options: RadioOption[]
  selectedOption: boolean | null | string | string[]
  onChange: (selected: boolean | string) => void
  label?: string
  singleSelect?: boolean
  register: UseFormRegister<FormInitialValues>
  formDataName: string
  errors?: { [key: string]: FieldError }
  dataId: string
}

const RadioGroup: React.FC<Props> = ({
  options,
  selectedOption,
  onChange,
  label,
  register,
  formDataName,
  errors,
  dataId,
}) => {
  return (
    <div className="w-full mx-auto">
      {label && <InputLabel size="lg">{label}</InputLabel>}
      <div className="space-y-6">
        {options.map((option, index) => (
          <Radio
            key={index}
            option={option}
            checked={selectedOption === option.value}
            onChange={() => onChange(option.value)}
            error={errors?.[dataId]?.message}
            register={register}
            formDataName={formDataName}
            errors={errors}
          />
        ))}
      </div>
    </div>
  )
}

export default RadioGroup
