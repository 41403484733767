import * as yup from "yup"

export const PHONE_REGEX_WITH_CODE = /^\(\d{3}\) \d{3}-\d{4}$/
export const EMAIL_REGEX_WITH_CODE = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
export const UUID4_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const PHONE_REGEX = /^\d{10}$/

export const PHONE_RULES = yup
  .string()
  .matches(PHONE_REGEX, "This phone number isn't valid")

export const PHONE_WITH_CODE_RULES = yup
  .string()
  .matches(
    PHONE_REGEX_WITH_CODE,
    "This phone number isn't valid. Please use format (XXX) XXX-XXXX.",
  )

export const EMAIL_RULES = yup.string().email("Invalid email")
