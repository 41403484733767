import Text from "components/text/Text"
import { ReactComponent as LogoSvg } from "assets/images/logo.svg"

const Footer = () => {
  return (
    <div className="mt-auto min-h-[100px] flex items-end">
      <div className="w-full py-8">
        <div className="flex flex-col items-center">
          <Text className="text-[8px]">POWERED BY</Text>
          <LogoSvg className="text-[#667085]" />
        </div>
      </div>
    </div>
  )
}

export default Footer
