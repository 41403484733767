type IAsyncConsultation = {
  [key: string]: {
    title: string
    description: string
  }
}

export const FORM_LABELS: IAsyncConsultation = {
  error: {
    title: "Oops! This Link Needs a Check-up",
    description: "Please review the URL and try again.",
  },
  signIn: {
    title: "Ready to discover your treatment options?",
    description:
      "Create an account or sign in to explore your treatment options.",
  },
  registration: {
    title: "Account Information",
    description: "Complete your profile by adding your personal details",
  },
  formSubmit: {
    title: "Ready for Your Next Step?",
    description: "",
  },
  submitSuccess: {
    title: "Success! Your Consultation Is Complete",
    description: "",
  },
  disclaimer: {
    title: "Disclaimer & Consent",
    description: "Review and accept the terms so we can safely proceed with your care"
  }
}
