import {
  Subscription,
  SubscriptionReferralLink,
} from "types/entities/subscriptionTypes"
import { User } from "../../types/entities/userTypes"
import { UserAddress } from "../../types/entities/userAddress"

export enum CheckoutStep {
  SUMMARY = "summary",
  ACCOUNT_INFO = "accountInfo",
  SHIPPING_INFO = "shippingInfo",
  PAYMENT_INFO = "paymentInfo",
  REVIEW = "review",
  CONFIRMATION = "confirmation",
}

export interface CheckoutData {
  accountInfo: {
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    gender?: string
    phone?: string
    email?: string
  }
  shippingInfo: {
    firstName?: string
    lastName?: string
    country?: string
    streetAddress?: string
    apt?: string
    city?: string
    state?: string
    zipCode?: string
  }
  paymentInfo: CheckoutPaymentInfo
  referralLinkId?: number | string
  subscriptionId?: number | string
}

export interface CheckoutPaymentInfo {
  finixToken?: string
  creditProcessor?: string | null
  last4?: string
  cardType?: string
  name?: string
  zipCode?: string
  fraudSessionId?: string
  idempotencyId?: string
}

export const DefaultCheckoutData = (
  subscription: Subscription,
  referralLink: SubscriptionReferralLink,
  user: User | null,
  address?: UserAddress,
  fraudSessionId?: string,
  idempotencyId?: string,
): CheckoutData => ({
  accountInfo: {
    firstName: user?.name_first,
    lastName: user?.name_last,
    dateOfBirth: user?.birth_date,
    gender: user?.gender_assigned_at_birth,
    phone: user?.phone,
    email: user?.email,
  },
  paymentInfo: {
    finixToken: user?.credit_authorization,
    creditProcessor: user?.credit_processor,
    last4: user?.credit_number_last_four,
    name: "",
    zipCode: "",
    cardType: user?.credit_type,
    fraudSessionId: fraudSessionId,
    idempotencyId: idempotencyId,
  },
  shippingInfo: {
    firstName: address?.nameFirst,
    lastName: address?.nameLast,
    streetAddress: address?.addressLine1,
    apt: address?.addressLine2,
    city: address?.addressCity,
    state: address?.addressRegion,
    zipCode: address?.addressPostalCode,
    country: "US",
  },
  subscriptionId: referralLink ? undefined : subscription.id,
  referralLinkId: referralLink ? referralLink.id : undefined,
})

export interface CheckoutDataKey {
  [key: string]: any
}
