import { FC, memo, ReactElement } from "react"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import Button from "components/buttons/Button"
import RightIcon from "assets/images/RightIcon"
import PhoneEmailInput from "components/inputs/PhoneEmailInput"
import useSendWellnessCode from "features/auth/useSendWellnessCode"
import AgreementLinks from "features/login/AgreementLinks"
import BrandHeader from "features/login/BrandHeader"
import { WellnessCodeRequest } from "types/authTypes"
import { DeactivationBanner } from "components/DeactivationBanner/DeactivationBanner"
interface IProps {
  setUserId: (value: string) => void
  setSignInValue: (value: WellnessCodeRequest) => void
  inPopup: boolean
  hideLogo?: boolean
}

const LoginContent: FC<IProps> = memo(
  ({ setUserId, inPopup, setSignInValue, hideLogo = false }): ReactElement => {
    const {
      handlePhoneEmailInputChange,
      handlePhoneValueSubmit,
      isLoading,
      isButtonDisabled,
      error,
      phoneEmailValue,
      isAccountDeactivated,
    } = useSendWellnessCode({ setUserId, setSignInValue, inPopup })

    const buttonText = hideLogo ? "Continue" : "Get Started"

    if (isAccountDeactivated) {
      return <DeactivationBanner />
    }

    return (
      <>
        <BrandHeader
          isLogoVisible={!inPopup}
          body="Sign in to your account"
          hideLogo={hideLogo}
          description={
            <>
              <p className="text-base font-normal font-body">
                Enter your mobile or email to continue.
              </p>
              <p className="text-xs font-normal font-body accent-gray-slate -mt-4">
                If you don’t have an account, we’ll create one for you.
              </p>
            </>
          }
        />
        <form className="w-full flex flex-col gap-1.31rem z-10">
          <PhoneEmailInput
            placeholder="Mobile or email"
            error={error}
            value={phoneEmailValue}
            onChange={handlePhoneEmailInputChange}
            inputSize="s"
            className="shadow-sm rounded-lg text-base accent-auroMetalSaurus font-normal"
          />
          <AgreementLinks />
          <div className={hideLogo ? "flex justify-end pt-5" : ""}>
            <Button
              type="submit"
              disabled={isButtonDisabled || isLoading}
              onClick={handlePhoneValueSubmit}
              size={hideLogo ? "xs" : "m"}
              isRounded={hideLogo}
              className={hideLogo ? "w-40" : "w-full"}
              fullWidth={!hideLogo}
              icon={
                isLoading ? (
                  <LoadingSvg />
                ) : (
                  <RightIcon disabled={isButtonDisabled || isLoading} />
                )
              }
            >
              {isLoading ? "Please wait..." : buttonText}
            </Button>
          </div>
        </form>
      </>
    )
  },
)

export default LoginContent
